import { useCallback, useMemo } from 'react';
import { useCount } from 'shared/foreground/databaseHooks';
import { globalState } from 'shared/foreground/models';

import { isReaderViewUrl } from '../utils/pathnameHelpers';
import useLocation from '../utils/useLocation';
import { useCurrentSidebarCategory } from './useCurrentCategory';

/**
 * Handles the logic for determining the empty state of document categories and the library.
 *
 * `isEmptyStateCategory` is true when the current category view is marked to show the empty state. This state might be useful to show UI for dismissing the emptyStateCategory.
 * `isInboxZero` is true when there are no documents in the current category view and the category is loaded.
 * `isEmptyState` is true when `isInboxZero` or `isEmptyStateCategory` is true.
 */
export const useIsInboxZero = () => {
  const location = useLocation();
  const { pathname } = location;

  const isReadingView = useMemo(() => isReaderViewUrl(pathname), [pathname]);
  const currentCategory = useCurrentSidebarCategory();
  const emptyStateCategory = globalState(
    useCallback((state) => state.emptyStateCategory, []),
  );

  const focusedDocumentListQuery = globalState(
    useCallback((state) => state.focusedDocumentListQuery, []),
  );

  const [count, { isFetchingInitialInput }] = useCount(
    'documents',
    focusedDocumentListQuery ?? undefined,
    {
      isEnabled: Boolean(focusedDocumentListQuery),
    },
  );

  const isSearchScreen = pathname.startsWith('/search');

  const isInboxZero = !isFetchingInitialInput && !isSearchScreen && !isReadingView && count === 0;

  const isEmptyStateCategory =
    currentCategory === 'library'
      ? Boolean(emptyStateCategory)
      : emptyStateCategory === currentCategory;

  const isEmptyState = isInboxZero || isEmptyStateCategory;

  return {
    isEmptyState,
    isInboxZero,
    isEmptyStateCategory,
  };
};
