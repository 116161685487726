import chunk from 'lodash/chunk';
import omit from 'lodash/omit';
import type { MangoQuery } from 'rxdb';
import { ulid } from 'ulid';

import { makeDocsWithTagNameQuery } from '../../../../database/queryHelpers';
// eslint-disable-next-line import/no-cycle
import { type FirstClassDocument, AnyDocument, UserEvent } from '../../../../types';
import type { DocumentTag } from '../../../../types/tags';
import { cleanAndValidateTagName, cleanUpTagName } from '../../../../utils/cleanAndValidateTagName';
import {
  addTagToDocObject,
  hasAnyTag,
  hasTag,
  removeTagFromDocObject,
} from '../../../../utils/tagHelpers';
// eslint-disable-next-line import/no-cycle
import database from '../../../database';
// eslint-disable-next-line import/no-cycle
import { updateState } from '../../../models';
import { ToastCategory } from '../../../Toaster';
import { createToast } from '../../../toasts.platform';
import type { StateUpdateOptionsWithoutEventName, StateUpdateResult } from '../../../types';
import {
  addTagNamesToRecentlyUsedTags,
  removeTagNamesFromRecentlyUsedTags,
} from '../../transientStateUpdaters/tagNamesUsedRecently';
import { doBulkActionOnDocs } from './bulk';
import { updateDocument } from './update';

export const toggleTag = async (
  documentId: string,
  tagName: string,
  options: Parameters<typeof addTag>[2],
) => {
  const doc = await database.collections.documents.findOne(documentId);
  if (!doc) {
    throw new Error('Document does not exist');
  }

  const wasTagOnDocument = hasTag(doc.tags, tagName);

  if (!wasTagOnDocument) {
    const { validationError } = cleanAndValidateTagName(tagName);
    if (validationError) {
      createToast({
        content: validationError.message,
        category: 'error',
      });
      return { userEvent: undefined };
    }
  }

  const stateUpdateOptions = {
    ...options,
    correlationId: options.correlationId || ulid(),
  };

  const stateUpdatePromises = [
    updateDocument(
      documentId,
      (doc) => {
        if (hasAnyTag(doc.tags, [tagName, cleanUpTagName(tagName)])) {
          removeTagFromDocObject(doc, tagName);
        } else {
          addTagToDocObject(doc, tagName);
        }
      },
      {
        ...stateUpdateOptions,
        eventName: 'document-tag-toggled',
      },
    ),
  ];

  if (wasTagOnDocument) {
    stateUpdatePromises.push(removeTagNamesFromRecentlyUsedTags([tagName], stateUpdateOptions));
  } else {
    stateUpdatePromises.push(addTagNamesToRecentlyUsedTags([tagName], stateUpdateOptions));
  }

  const [documentUpdateResult] = await Promise.all(stateUpdatePromises);
  return documentUpdateResult;
};

// We have api for adding tags and we should keep it consistent when making any changes - See ./reader/api.py (document_tags_endpoint)
export const addTag = async (
  documentId: string,
  tagName: string,
  options: StateUpdateOptionsWithoutEventName,
): StateUpdateResult => {
  const { validationError } = cleanAndValidateTagName(tagName);
  if (validationError) {
    createToast({
      content: validationError.message,
      category: 'error',
    });
    return { userEvent: undefined };
  }

  const stateUpdateOptions = {
    ...options,
    correlationId: options.correlationId || ulid(),
  };

  const stateUpdatePromises = [];

  if (options.userInteraction) {
    stateUpdatePromises.push(addTagNamesToRecentlyUsedTags([tagName], stateUpdateOptions));
  }

  stateUpdatePromises.push(
    updateDocument(
      documentId,
      (doc) => {
        addTagToDocObject(doc, tagName);
      },
      {
        ...stateUpdateOptions,
        eventName: 'document-tag-added',
      },
    ),
  );

  const [, documentUpdateResult] = await Promise.all(stateUpdatePromises);
  return documentUpdateResult;
};

export const addTags = async (
  documentId: string,
  tagNames: string[],
  options: StateUpdateOptionsWithoutEventName & {
    type?: DocumentTag['type'];
  },
): StateUpdateResult => {
  const cleanTagNames: DocumentTag['name'][] = [];
  for (const tagName of tagNames) {
    const { cleanTagName, validationError } = cleanAndValidateTagName(tagName);
    if (validationError) {
      createToast({
        content: `${validationError.message} (tag name: ${tagName})`,
        category: 'error',
      });
      return { userEvent: undefined };
    }
    cleanTagNames.push(cleanTagName);
  }

  const stateUpdateOptions = {
    ...omit(options, ['type']),
    correlationId: options.correlationId || ulid(),
  };

  const [documentUpdateResult] = await Promise.all([
    updateDocument(
      documentId,
      (doc) => {
        cleanTagNames.forEach((cleanTagName) => addTagToDocObject(doc, cleanTagName, options.type));
      },
      {
        ...stateUpdateOptions,
        eventName: 'document-tag-added',
      },
    ),
    addTagNamesToRecentlyUsedTags(cleanTagNames, stateUpdateOptions),
  ]);

  return documentUpdateResult;
};

export const removeTag = async (
  documentId: string,
  tagName: string,
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): StateUpdateResult => {
  const stateUpdateOptions = {
    ...omit(options, ['showToast']),
    correlationId: options.correlationId || ulid(),
  };

  const [documentUpdateResult] = await Promise.all([
    updateDocument(
      documentId,
      (doc) => {
        removeTagFromDocObject(doc, tagName);
      },
      {
        ...stateUpdateOptions,
        eventName: 'document-tag-removed',
      },
    ),
    removeTagNamesFromRecentlyUsedTags([tagName], stateUpdateOptions),
  ]);

  if (options.showToast) {
    const content = 'Tag removed';
    createToast({
      content,
      category: 'success',
      undoableUserEventId: options.isUndoable
        ? (documentUpdateResult.userEvent as UserEvent).id
        : undefined,
    });
  }

  return documentUpdateResult;
};

export const removeTags = async (
  documentId: string,
  tagNames: string[],
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): StateUpdateResult => {
  const stateUpdateOptions = {
    ...omit(options, ['showToast']),
    correlationId: options.correlationId || ulid(),
  };

  const [documentUpdateResult] = await Promise.all([
    updateDocument(
      documentId,
      (doc) => {
        tagNames.forEach((tagName) => {
          removeTagFromDocObject(doc, tagName);
        });
      },
      {
        ...options,
        eventName: 'document-tags-removed',
      },
    ),
    removeTagNamesFromRecentlyUsedTags(tagNames, stateUpdateOptions),
  ]);

  if (options.showToast) {
    const content = 'Tags removed';
    createToast({
      content,
      category: 'success',
      undoableUserEventId: options.isUndoable
        ? (documentUpdateResult.userEvent as UserEvent).id
        : undefined,
    });
  }

  return documentUpdateResult;
};

const getDocIdsUsingTagName = (tagName: DocumentTag['name']) => {
  return database.collections.documents.findIds(makeDocsWithTagNameQuery(tagName));
};

export const renameTags = async ({
  prevTagNames,
  newTagName,
  options,
}: {
  prevTagNames: DocumentTag['name'][];
  newTagName: DocumentTag['name'];
  options: StateUpdateOptionsWithoutEventName;
}) => {
  const { cleanTagName: cleanNewTagName, validationError } = cleanAndValidateTagName(newTagName);
  if (validationError) {
    createToast({
      content: `${validationError.message} (tag name: ${newTagName})`,
      category: 'error',
    });
    return { userEvent: undefined };
  }

  const stateUpdateOptions = {
    ...options,
    correlationId: options.correlationId || ulid(),
  };

  const stateUpdatePromises: Promise<unknown>[] = prevTagNames.map(async (prevTagName) => {
    const query = makeDocsWithTagNameQuery(prevTagName);
    const docIds = query && await database.collections.documents.findIds(query);

    // Only update 100 docs at a time here to avoid huge state updates that break syncing.
    // I tried doing this by making this a bulk action, but it was way too hard/awkward due to the bulk interface.
    for (const chunkOfIdsToUpdate of chunk(docIds, 50)) {
      await database.collections.documents.findByIdsAndIncrementalModify(
          chunkOfIdsToUpdate,
          (doc) => {
            removeTagFromDocObject(doc, prevTagName);
            addTagToDocObject(doc, cleanNewTagName);
            return doc;
          },
          { ...stateUpdateOptions, eventName: 'tag-renamed' },
        );
    }
  });

  stateUpdatePromises.push(
    updateState(
      (state) => {
        // Make sure to update the queries of the views using the prev tag
        const views = state.persistent.filteredViews ?? {};
        for (const view of Object.values(views)) {
          for (const prevTagName of prevTagNames) {
            const { cleanTagName: cleanPrevTagName } = cleanAndValidateTagName(prevTagName);

            view.query = view.query.replace(`tag:"${prevTagName}"`, `tag:"${cleanNewTagName}"`);
            if (cleanNewTagName) {
              view.query = view.query.replace(`tag:"${cleanPrevTagName}"`, `tag:"${cleanNewTagName}"`);
            }

            view.query = view.query.replace(`tag:${prevTagName}`, `tag:"${cleanNewTagName}"`);
            if (cleanNewTagName) {
              view.query = view.query.replace(`tag:${cleanPrevTagName}`, `tag:"${cleanNewTagName}"`);
            }
          }
        }
      },
      {
        ...stateUpdateOptions,
        eventName: 'tag-renamed-in-views',
      },
    ),
  );

  stateUpdatePromises.push(removeTagNamesFromRecentlyUsedTags(prevTagNames, stateUpdateOptions));
  stateUpdatePromises.push(addTagNamesToRecentlyUsedTags([newTagName], stateUpdateOptions));

  await Promise.all(stateUpdatePromises);
};

export const renameTag = async ({
  prevTagName,
  newTagName,
  options,
}: {
  prevTagName: DocumentTag['name'];
  newTagName: DocumentTag['name'];
  options: StateUpdateOptionsWithoutEventName;
}) => {
  await renameTags({ prevTagNames: [prevTagName], newTagName, options });
};


export const addTagToAllDocsInList = async ({
  listQuery,
  tagName,
  options,
}: {
  listQuery: MangoQuery<AnyDocument>;
  tagName: string;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const { cleanTagName, validationError } = cleanAndValidateTagName(tagName);
  if (validationError) {
    createToast({
      content: validationError.message,
      category: 'error',
    });
    return;
  }

  const stateUpdateOptions = {
    ...options,
    correlationId: options.correlationId || ulid(),
  };

  const stateUpdatePromises: Promise<unknown>[] = [
    doBulkActionOnDocs({
      docQuery: listQuery,
      createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
        let toastMessage;

        if (updatedDocsCount < totalDocsToUpdateCount) {
          toastMessage = `Adding ${cleanTagName} tag to ${updatedDocsCount} / ${totalDocsToUpdateCount} documents...`;
        } else {
          toastMessage = `${cleanTagName} tag added`;
        }

        return {
          content: toastMessage,
          category: 'success' as ToastCategory,
          undoableUserEventId: (updateResult?.userEvent as UserEvent)?.id,
        };
      },
      applyBulkActionToBatch: async (docIds, options) => {
        const res =
          await database.collections.documents.findByIdsAndIncrementalModify<FirstClassDocument>(
            docIds,
            (doc) => {
              addTagToDocObject(doc, cleanTagName);
              return doc;
            },
            options,
          );
        return res;
      },
      batchSize: 200,
      options: {
        ...stateUpdateOptions,
        eventName: 'tag-added-to-all-documents-in-list',
      },
    }),
  ];

  stateUpdatePromises.push(addTagNamesToRecentlyUsedTags([tagName], stateUpdateOptions));

  await Promise.all(stateUpdatePromises);
};

export const removeTagFromAllDocs = ({
  tagName,
  options,
}: { tagName: DocumentTag['name']; options: StateUpdateOptionsWithoutEventName; }) => {
  const listQuery = makeDocsWithTagNameQuery(tagName);
  removeTagFromAllDocsInList({ listQuery, tagName, options });
};

export const removeTagFromAllDocsInList = async ({
  listQuery,
  tagName,
  options,
}: {
  listQuery: MangoQuery<AnyDocument>;
  tagName: string;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const stateUpdateOptions = {
    ...options,
    correlationId: options.correlationId || ulid(),
  };

  const stateUpdatePromises: Promise<unknown>[] = [
    doBulkActionOnDocs({
      docQuery: listQuery,
      createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
        let toastMessage;

        if (updatedDocsCount < totalDocsToUpdateCount) {
          toastMessage = `Removing tag from ${updatedDocsCount} / ${totalDocsToUpdateCount} documents...`;
        } else {
          toastMessage = `${tagName} tag removed`;
        }

        return {
          content: toastMessage,
          category: 'success' as ToastCategory,
          undoableUserEventId: (updateResult?.userEvent as UserEvent)?.id,
        };
      },
      applyBulkActionToBatch: async (docIds, options) => {
        const res =
          await database.collections.documents.findByIdsAndIncrementalModify<FirstClassDocument>(
            docIds,
            (doc) => {
              removeTagFromDocObject(doc, tagName);
              return doc;
            },
            options,
          );
        return res;
      },
      batchSize: 200,
      options: {
        ...stateUpdateOptions,
        eventName: 'tag-removed-from-all-documents-in-list',
      },
    }),
  ];

  stateUpdatePromises.push(removeTagNamesFromRecentlyUsedTags([tagName], stateUpdateOptions));

  await Promise.all(stateUpdatePromises);
};

export const removeTagsFromAllDocs = async ({ tagNames }: { tagNames: DocumentTag['name'][]; }) => {
  let docIdsSet = new Set<string>();

  for (const tagName of tagNames) {
    const docIdsForTag = await getDocIdsUsingTagName(tagName);
    docIdsSet = new Set([...docIdsSet, ...docIdsForTag]);
  }

  const tagOrTags = tagNames.length > 1 ? 'tags' : 'tag';

  const stateUpdateOptions = {
    correlationId: ulid(),
    eventName: 'tags-removed-from-all-documents-in-list',
    userInteraction: 'click',
  };

  const stateUpdatePromises: Promise<unknown>[] = [
    doBulkActionOnDocs({
      docQuery: {
        selector: {
          id: { $in: Array.from(docIdsSet) },
        },
      },
      createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
        let toastMessage;

        if (updatedDocsCount < totalDocsToUpdateCount) {
          toastMessage = `Removing ${tagOrTags} from ${updatedDocsCount} / ${totalDocsToUpdateCount} documents...`;
        } else {
          toastMessage = `${tagNames.length} ${tagOrTags} removed`;
        }

        return {
          content: toastMessage,
          category: 'success' as ToastCategory,
          undoableUserEventId: (updateResult?.userEvent as UserEvent)?.id,
        };
      },
      applyBulkActionToBatch: async (docIds, options) => {
        const res =
          await database.collections.documents.findByIdsAndIncrementalModify<FirstClassDocument>(
            docIds,
            (doc) => {
              const tagNamesToRemoveInDoc = tagNames.filter((tagName) => hasTag(doc.tags, tagName));
              for (const tagNameToRemove of tagNamesToRemoveInDoc) {
                removeTagFromDocObject(doc, tagNameToRemove);
              }
              return doc;
            },
            options,
          );
        return res;
      },
      batchSize: 200,
      options: {
        ...stateUpdateOptions,
        eventName: 'tags-removed-from-all-documents-in-list',
      },
    }),
  ];

  stateUpdatePromises.push(addTagNamesToRecentlyUsedTags(tagNames, stateUpdateOptions));

  await Promise.all(stateUpdatePromises);
};
