import throttle from 'lodash/throttle';
import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { contentFocusIndicatorFocusedTargetClass } from 'shared/constants.platform';
import eventEmitter from 'shared/foreground/eventEmitter';
import { globalState } from 'shared/foreground/models';
import {
  setYtPlayerHeight as setYtPlayerHeightInClientState,
  slowDownYtPlaybackRate,
  speedUpYtPlaybackRate,
  toggleYTAutoScroll,
} from 'shared/foreground/stateUpdaters/clientStateUpdaters/youtubePlayer';
import {
  setSeekYtPlayerTo,
  setYtVideoPlaying,
  toggleYtVideoPlaying,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/youtubePlayer';
import useGlobalStateWithFallback from 'shared/foreground/utils/useGlobalStateWithFallback';
import { useSeekYouTubeVideoToInitialProgress } from 'shared/foreground/utils/useSeekYouTubeVideoToInitialProgress';
import { useUpdateVideoScrollPosition } from 'shared/foreground/utils/useUpdateVideoScrollPosition';
import {
  getCurrentTimeIndex,
  getElementFromTime,
  getStartTimeFromEl,
  getYoutubeIdFromUrl,
} from 'shared/foreground/utils/youtubeHelpers';
import type { DocumentId, Highlight } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { DefaultYouTubePlaybackRate } from 'shared/utils/youtubeConstants';

import { useIsLeftSidebarHidden, useIsRightSidebarHidden } from '../../hooks/hooks';
import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import convertVhToPxSafe from '../../utils/convertVhToPxSafe';
import { useShortcutsMap } from '../../utils/shortcuts';
import { baseShortcuts } from '../../utils/shortcuts/defaultsByLayout';
import { FailedToRenderHighlightsBanner } from '../FailedToRenderHighlightsBanner';
import ResizeHandle from '../ResizeHandle';
import Spinner from '../Spinner';
import styles from './EmbeddedYoutubeDocument.module.css';

type EmbeddedYoutubeDocumentProps = {
  docId: DocumentId;
  url: string;
  transcriptHtml: string;
  scrollDepth?: number | null;
  failedExtensionOrYouTubeHighlightsHighlightIds?: Highlight['id'][];
  onVideoResizingStateChange?: (isResizing: boolean) => void;
  contentCurrentScrollOffsetY?: number;
};

const EmbeddedYoutubeDocument = forwardRef<HTMLDivElement, EmbeddedYoutubeDocumentProps>(
  function EmbeddedYoutubeDocument(
    {
      docId,
      url,
      transcriptHtml,
      scrollDepth,
      failedExtensionOrYouTubeHighlightsHighlightIds,
      onVideoResizingStateChange,
      contentCurrentScrollOffsetY = 0,
    },
    ref,
  ): JSX.Element {
    const [isLoading, setIsLoading] = useState(true);
    const [playedSeconds, setPlayedSeconds] = useState<number | undefined>(undefined);
    const [startTimes, setStartTimes] = useState<number[] | null>(null);
    const [isFailedToRenderHighlightsBannerVisible, setIsFailedToRenderHighlightsBannerVisible] =
    useState(false);
    const seekTo = globalState(useCallback((state) => state.youtube.seekTo, []));
    const playbackRate = globalState(
      useCallback((state) => state.client.youtube.playbackRate || DefaultYouTubePlaybackRate, []),
    );
    // We use local state for immediate UI updates but throttle saving to client state to avoid excessive updates
    const clientPlayerHeight = useGlobalStateWithFallback(
      convertVhToPxSafe(50),
      useCallback((state) => state.client.youtube.playerHeight, []),
    );
    const getPlayerMaxHeight = useCallback(() => convertVhToPxSafe(65), []);
    const getPlayerMinSize = useCallback(() => convertVhToPxSafe(isFailedToRenderHighlightsBannerVisible ? 20 : 10), [isFailedToRenderHighlightsBannerVisible]);
    const [localPlayerHeight, setLocalPlayerHeight] = useState(clientPlayerHeight);
    const [playerMaxHeight, setPlayerMaxHeight] = useState(getPlayerMaxHeight());
    const [playerMinSize, setPlayerMinSize] = useState(getPlayerMinSize());
    const currentSelectedStartTime = useRef<number | null>(null);
    const videoId = useMemo(() => getYoutubeIdFromUrl(url), [url]);
    const playerRef = useRef<ReactPlayer>(null);
    const autoScrollEnabled = globalState(useCallback((state) => state.client.youtube.autoScroll, []));
    const ytIsPlaying = globalState(useCallback((state) => state.youtube.isPlaying, []));
    const isVideoHeaderShown = globalState(useCallback((state) => state.isVideoHeaderShown, []));
    const shortcutsMap = useShortcutsMap();
    const [videoDuration, setVideoDuration] = useState<number | undefined>(undefined);
    const isLeftSidebarHidden = useIsLeftSidebarHidden();
    const isRightSidebarHidden = useIsRightSidebarHidden();

    useEffect(() => {
      const listener = () => {
        setPlayerMaxHeight(getPlayerMaxHeight());
        setPlayerMinSize(getPlayerMinSize());
      };
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
    }, [getPlayerMaxHeight, getPlayerMinSize]);

    const seekToCallback = playerRef.current?.seekTo
      ? (seconds: number) => playerRef.current?.seekTo(seconds)
      : undefined;

    useSeekYouTubeVideoToInitialProgress({
      scrollDepth,
      videoDuration,
      isPlaying: ytIsPlaying,
      seekTo: seekToCallback,
    });

    useUpdateVideoScrollPosition({
      docId,
      videoDuration,
      playedSeconds,
    });

    const seekToFocusedParagraph = useCallback(() => {
      const currentFocusedEl = document.querySelector(
        `.${contentFocusIndicatorFocusedTargetClass} span[data-rw-start]`,
      ) as HTMLSpanElement | null;

      if (currentFocusedEl) {
        const newSeekTo = getStartTimeFromEl(currentFocusedEl);
        if (newSeekTo) {
          setSeekYtPlayerTo(newSeekTo);
        }
      }
    }, []);

    useEffect(() => {
      eventEmitter.on('yt-seek-to-focused-paragraph', seekToFocusedParagraph);

      return () => {
        eventEmitter.off('yt-seek-to-focused-paragraph', seekToFocusedParagraph);
      };
    }, [seekToFocusedParagraph]);

    useKeyboardShortcutPreventDefault(
      shortcutsMap[ShortcutId.CmdOrCtrlAndEnter],
      seekToFocusedParagraph,
      {
        description: 'Seek to current focused transcript',
      },
    );

    useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.SpeedUpPlayback], speedUpYtPlaybackRate, {
      description: 'Speed up YouTube playback rate',
    });

    useKeyboardShortcutPreventDefault(
      shortcutsMap[ShortcutId.SlowDownPlayBack],
      slowDownYtPlaybackRate,
      {
        description: 'Slow down YouTube playback rate',
      },
    );

    useKeyboardShortcutPreventDefault(
      shortcutsMap[ShortcutId.ToggleYtAutoScroll],
      useCallback(() => toggleYTAutoScroll({ userInteraction: 'keypress' }), []),
      {
        description: 'Toggle YouTube auto scroll',
      },
    );

    useKeyboardShortcutPreventDefault(
      useMemo(() => [baseShortcuts.Space], []),
      useCallback(() => toggleYtVideoPlaying(), []),
      { description: 'Toggle video playing' },
    );

    const secondsToChange = 15;

    const skipForward = useCallback(() => {
      setSeekYtPlayerTo((playedSeconds ?? 0) + secondsToChange);
    }, [playedSeconds]);

    useKeyboardShortcutPreventDefault(
      shortcutsMap[ShortcutId.SkipForward],
      useCallback(() => skipForward(), [skipForward]),
      { description: 'Skip forward in YouTube video' },
    );

    useEffect(() => {
      eventEmitter.on('yt-skip-forward', skipForward);

      return () => {
        eventEmitter.off('yt-skip-forward', skipForward);
      };
    }, [skipForward]);

    const skipBackwards = useCallback(() => {
      const newSeekTo = (playedSeconds ?? 0) - secondsToChange;
      if (newSeekTo > 0) {
        setSeekYtPlayerTo(newSeekTo);
      } else {
        setSeekYtPlayerTo(0);
      }
    }, [playedSeconds]);

    useKeyboardShortcutPreventDefault(
      shortcutsMap[ShortcutId.SkipBackwards],
      useCallback(() => skipBackwards(), [skipBackwards]),
      { description: 'Skip backwards in YouTube video' },
    );

    useEffect(() => {
      eventEmitter.on('yt-skip-backwards', skipBackwards);

      return () => {
        eventEmitter.off('yt-skip-backwards', skipBackwards);
      };
    }, [skipBackwards]);

    // Seek to time if seekTo is set and then reset it
    useEffect(() => {
      if (!seekTo || !playerRef.current) {
        return;
      }

      playerRef.current.seekTo(seekTo);
      setYtVideoPlaying(true);
      setSeekYtPlayerTo(null);
    }, [seekTo]);

    // Get all the start times from transcript
    useEffect(() => {
      if (startTimes || !transcriptHtml) {
        return;
      }

      const doc = new DOMParser().parseFromString(transcriptHtml, 'text/html');

      const times = Array.from(doc.querySelectorAll<HTMLElement>('span[data-rw-start]'), (el) =>
        Number(el.dataset.rwStart));
      setStartTimes(times);
    }, [startTimes, transcriptHtml]);

    // Add click listener to transcript elements to seek to time
    useEffect(() => {
      const onTranscriptClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const seekTo = getStartTimeFromEl(target);

        if (seekTo) {
          setSeekYtPlayerTo(seekTo);
        }
      };

      document.addEventListener('click', onTranscriptClick);

      return () => document.removeEventListener('click', onTranscriptClick);
    }, []);

    // Remove previous highlight and add new to current transcript
    useEffect(() => {
      if (!playedSeconds || !startTimes) {
        return;
      }

      // Remove previous highlight
      if (currentSelectedStartTime && currentSelectedStartTime.current) {
        const currentTranscript = getElementFromTime(currentSelectedStartTime.current);
        if (currentTranscript) {
          currentTranscript.classList.remove(styles.teleprompter);
        }
      }

      // Add new highlight
      const currentStartTimeIndex = getCurrentTimeIndex({
        times: startTimes,
        currentTime: playedSeconds,
      });
      const currentStartTime = currentStartTimeIndex !== null && startTimes[currentStartTimeIndex];

      if (currentStartTime) {
        const el = getElementFromTime(currentStartTime);

        if (!el) {
          return;
        }

        el.classList.add(styles.teleprompter);

        currentSelectedStartTime.current = currentStartTime;

        if (autoScrollEnabled) {
          // TODO: The 200px offset should be calculated dynamically based on viewport height
          // to ensure the scrolled content remains visible when the video player is at its maximum size
          const paddingTop = localPlayerHeight + 200;
          const contentWrapper = document.getElementById('document-reader-root');
          contentWrapper?.scroll({ top: el.offsetTop - paddingTop, behavior: 'smooth' });
          eventEmitter.emit('update-content-focus-indicator-target', { target: el });
        }
      }
    }, [playedSeconds, startTimes, autoScrollEnabled, localPlayerHeight]);

    const throttledSetYtPlayerHeightInClientState = useMemo(
      () => throttle((value: number) => setYtPlayerHeightInClientState(value, { userInteraction: 'resize' }), 2000, { trailing: true }),
      [],
    );

    const [isResizingVideo, setIsResizingVideo] = useState(false);

    useEffect(() => {
      onVideoResizingStateChange?.(isResizingVideo);
    }, [isResizingVideo, onVideoResizingStateChange]);

    useEffect(() => {
      const ytContainer: HTMLElement | null = document.querySelector(`.${styles.ytPlayerContainer}`);

      if (!ytContainer) {
        return;
      }

      const iframeWrapperHeight = localPlayerHeight + 85;
      // --iframe-height can't be bigger than --iframe-wrapper-height
      const iframeHeight = Math.min(isFailedToRenderHighlightsBannerVisible ? localPlayerHeight - 23 : localPlayerHeight + 24, iframeWrapperHeight);

      ytContainer.style.setProperty('--iframe-wrapper-height', `${iframeWrapperHeight}px`);
      ytContainer.style.setProperty('--iframe-height', `${iframeHeight}px`);
      eventEmitter.emit('refocus-inbox-focus-indicator');

      throttledSetYtPlayerHeightInClientState(localPlayerHeight);
    }, [localPlayerHeight, playerMaxHeight, throttledSetYtPlayerHeightInClientState, isFailedToRenderHighlightsBannerVisible]);

    const resizeHandleWrapperClassName = useMemo(() => {
      const classes = [styles.resizeHandleWrapper];
      if (isLeftSidebarHidden && isRightSidebarHidden || !isLeftSidebarHidden && !isRightSidebarHidden) {
        return classes.join(' ');
      }

      if (isLeftSidebarHidden) {
        classes.push(styles.handleRightPadding);
      }

      if (isRightSidebarHidden) {
        classes.push(styles.handleLeftPadding);
      }

      return classes.join(' ');
    }, [isLeftSidebarHidden, isRightSidebarHidden]);

    return (
      <div
        ref={ref}
        className={`${styles.ytPlayerContainer} ${isVideoHeaderShown ? '' : styles.isVideoHeaderHidden} ${
          isFailedToRenderHighlightsBannerVisible ? styles.isFailedToRenderHighlightsBannerVisible : ''
        }`}
        style={{ height: localPlayerHeight }}
      >
        <ReactPlayer
          ref={playerRef}
          url={`https://www.youtube.com/embed/${videoId}`}
          controls
          style={{ height: localPlayerHeight, display: isLoading ? 'none' : '' }}
          className={styles.ytPlayer}
          playing={ytIsPlaying}
          playbackRate={playbackRate}
          onProgress={(p) => setPlayedSeconds(p.playedSeconds)}
          onPause={() => setYtVideoPlaying(false)}
          onPlay={() => setYtVideoPlaying(true)}
          onDuration={setVideoDuration}
          onReady={() => {
            setIsLoading(false);
            eventEmitter.emit('refocus-content-focus-indicator');
          }}
        />

        <ResizeHandle
          wrapperClassName={resizeHandleWrapperClassName}
          onHandleMove={useCallback(
            (resizedValue) => {
              setLocalPlayerHeight((prev) => {
                const newSize = Math.max(prev + resizedValue, playerMinSize);
                return Math.min(newSize, playerMaxHeight);
              });
              setIsResizingVideo(true);
            },
            [setIsResizingVideo, playerMaxHeight, playerMinSize],
          )}
          onStartResizing={useCallback(() => setIsResizingVideo(true), [setIsResizingVideo])}
          onStopResizing={useCallback(() => setIsResizingVideo(false), [setIsResizingVideo])}
        />

        {contentCurrentScrollOffsetY > 0 && <div className={styles.fadeOutEffect} />}

        {!isLoading && failedExtensionOrYouTubeHighlightsHighlightIds && failedExtensionOrYouTubeHighlightsHighlightIds.length > 0 &&
          <FailedToRenderHighlightsBanner
            highlightIds={failedExtensionOrYouTubeHighlightsHighlightIds}
            onVisibleChange={setIsFailedToRenderHighlightsBannerVisible}
            className={styles.failedToRenderHighlightsBanner}
            text={`You made some highlight${failedExtensionOrYouTubeHighlightsHighlightIds.length === 1 ? '' : 's'} before this transcript was enhanced and can't be shown overlaid.`}
          />
        }

        {isLoading &&
          <div className={styles.loadingWrapper}>
            <Spinner />
          </div>
        }

        {isResizingVideo && <div className={styles.resizingOverlay} />}
      </div>
    );
  },
);

export default memo(EmbeddedYoutubeDocument);
