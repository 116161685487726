import foregroundEventEmitter from '../../foreground/eventEmitter';
import { populateFocusableElements } from '../../foreground/utils/findAllFocusableElements';
import {
  areTwoRectsIntersecting,
  findCenteredElementInViewport,
} from '../../foreground/utils/findCenteredElementInViewport';
import getDeepestWith from '../../foreground/utils/getDeepestWith';
import { isNodeAnHTMLElement } from '../../foreground/utils/isNodeAnHTMLElement';
import { LenientReadingPosition } from '../../types';
import { isHTMLElement, isTextNode } from '../../typeValidators';
import nowTimestamp from '../../utils/dates/nowTimestamp';
import { DeferredPromise } from '../../utils/DeferredPromise';
import delay from '../../utils/delay';
import makeLogger from '../../utils/makeLogger';
import { ScrollingManagerError } from './errors';
import { getATagAncestor } from './getATagAncestor';
import { animateEndOfReadingButton } from './initEndOfReading';
// eslint-disable-next-line import/no-cycle
import { PageRect, ScrollingManager } from './ScrollingManager';
import { CLICKABLE_TAGS, CLICKABLE_TAGS_THROUGH_PAGINATION_MARGINS, PAGINATION_DOCUMENT_TOP_MARGIN } from './types';

const TOP_MARGIN = 90;
const BOTTOM_MARGIN = 130;

const TWEET_CLASS_NAME = 'rw-embedded-tweet';
// Add tags here that should not have page borders cross them
const NON_SPLITTABLE_TAGS = new Set<string>(['IMG', 'VIDEO', 'FIGURE']);
const SPLIT_BORDER_HEIGHT = 0;
const MINIMUM_PAGE_HEIGHT = 100;

export class PaginatedScrollingManager extends ScrollingManager {
  currentlyScrollingBecauseOfTouch = false;
  currentHeight = 0;
  logger = makeLogger(__filename, { shouldLog: false });

  selectionChangeTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  areTouchesDisabledBecauseOfSelection = false;
  pageRects: PageRect[] = [];
  topPageSnapshotElement: HTMLElement | undefined;
  bottomPageSnapshotElement: HTMLElement | undefined;

  scrollStartTime = 0;
  startPageOnTouch: number | null = 0;
  startTouchY: number | null = null;
  startTouchClientY = 0;
  startScrollYOnSelect: number | null = null;
  endTouchClientY = 0;
  // Used for tracking direction changes, its throttled to help with very sensitive changed
  throttledTouchClientY = 0;
  endTouchY = 0;
  touchYDirection = 0;
  startTouchX = 0;
  endTouchX = 0;

  // Empirically determined values for determining what swipe speed warrants a page turn
  velocityThreshold = 0.4;
  verticalSwipeDistanceMinimumThreshold = 40;
  // If we swipe this far, its definitely a vertical swipe
  verticalSwipeDistanceConclusiveThreshold = 150;
  tapTimeThreshold = 200;
  horizontalSwipeDistanceThreshold = 80;

  totalVerticalMarginHeight = 0;

  scrollTimeForPage = 300;
  iosScrollDelay = 250;
  hapticsOnScrollTimeModifier = 20;
  scrollStepDelay = 8;

  currentlyTransitioningPages = false;
  // While we transition, we might log multiple gestures to swipe up or down
  // keep an array of all ongoing page transitions
  currentTransitionPromises: DeferredPromise<boolean>[] = [];


  debugFreeScroll = false;
  currentPage = 0;
  enableScrollTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
  centerElementUpdatingBlockedTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

  latestSelectionYCoord: number | null = null;
  latestSelectionAnchorRange: Range | null = null;

  initialPageOnScrollStart = 0;

  boundOnHighlightsRemoved: typeof this.onHighlightsRemoved | undefined;

  pageScrollOffset = 1;
  destroy () {
    super.destroy();
    this.removeAllEventListeners();
    if (!this.headerContainer) {
      throw new ScrollingManagerError('destroy: No header container found');
    }
    this.headerContainer.style.top = '0px';
    if (this.boundOnHighlightsRemoved) {
      foregroundEventEmitter.off('content-frame:highlights-removed', this.boundOnHighlightsRemoved);
    }
  }

  onContentChanged() {
    super.onContentChanged();
  }

  init(): void {
    if (this.initialized) {
      return;
    }
    super.init();

    this.updatingCenterElementDisabled = true;
    this.disableScrollingWithTouch();
    if (!this.documentRoot || !this.documentTextContent) {
      throw new ScrollingManagerError('No documentRoot element found');
    }
    this.documentRoot.classList.add('opaque-document-root');
    if (!this.highlightableElements.length) {
      populateFocusableElements(this.documentTextContent, this.highlightableElements);
    }
    this.addEventListener(this.document, 'touchstart', this.onTouchStart.bind(this));
    this.addEventListener(this.document, 'touchmove', this.onTouchMove.bind(this));
    this.addEventListener(this.document, 'touchend', this.onTouchEnd.bind(this));
    this.addEventListener(this.window, 'scroll', this.onScroll.bind(this));
    this.addEventListener(this.document, 'selectionchange', this.onSelectionChange.bind(this));

    this.boundOnHighlightsRemoved = this.onHighlightsRemoved.bind(this);
    foregroundEventEmitter.on('content-frame:highlights-removed', this.boundOnHighlightsRemoved);

    this.updateCurrentCenteredElement();

    this.updateCurrentHeight();
    this.pageHeight = Math.min(this.window.screen.height, this.window.innerHeight) - BOTTOM_MARGIN - TOP_MARGIN;

    if (!this.documentRoot) {
      throw new ScrollingManagerError('No documentRoot element found');
    }

    this.logger.log('Init ', this.documentTextContent?.getBoundingClientRect().height);
    const bottomPageContentResult = this.document.querySelector<HTMLElement>(
      '.absolutely-positioned-content.bottom',
    );
    const topPageContentResult = this.document.querySelector<HTMLElement>(
      '.absolutely-positioned-content.top',
    );
    if (!bottomPageContentResult || !topPageContentResult) {
      throw new ScrollingManagerError('No bottom or top content element found');
    }

    const bottomMargin = this.document.querySelector('.bottom-margin');
    if (!bottomMargin) {
      throw new ScrollingManagerError('No bottom margins found');
    }
    this.totalVerticalMarginHeight =
      PAGINATION_DOCUMENT_TOP_MARGIN + bottomMargin.getBoundingClientRect().height;

    this.bottomPageSnapshotElement = bottomPageContentResult;
    this.topPageSnapshotElement = topPageContentResult;
    // Block updating center element for 2000ms so that resizes can retain our position
    this.blockUpdatingCenterElementForNMS();
    this.enableScrollingWithTouch();
    this.initialized = true;
  }


  getNumberOfWordsBetweenReadingPositions(previousReadingPosition: LenientReadingPosition, newReadingPosition: LenientReadingPosition): number {
    if (!previousReadingPosition.scrollDepth || !newReadingPosition.scrollDepth) {
      return 0;
    }
    if (previousReadingPosition.scrollDepth >= newReadingPosition.scrollDepth) {
      return 0;
    }
    const previousPageNum = this.getPageNumberFromCoordinate(previousReadingPosition.scrollDepth * this.currentHeight + TOP_MARGIN + 1);
    const newPageNum = this.getPageNumberFromCoordinate(newReadingPosition.scrollDepth * this.currentHeight + TOP_MARGIN + 1);

    const previousRect = this.getBoundedPageRect(previousPageNum);
    const newPageRect = this.getBoundedPageRect(newPageNum);
    const rect = {
      top: previousRect.top,
      bottom: newPageRect.top,
      // this value doesn't matter here
      topPageDividerHeight: 0,
    };
    return this.getNumberOfWordsInRect(rect);
  }

  getScrollingElementTop() {
    return this.getScrollingElement().scrollTop - PAGINATION_DOCUMENT_TOP_MARGIN;
  }

  getScrollingElementMaxScroll() {
    const { scrollHeight } = this.getScrollingElement();
    return scrollHeight - this.totalVerticalMarginHeight;
  }

  onHighlightsRemoved() {
    this.refreshPageSnapshotsForCurrentPage();
  }

  setScrollingElementTop(newTop: number) {
    this.getScrollingElement().scrollTop = newTop + PAGINATION_DOCUMENT_TOP_MARGIN;
  }

  scrollingElementScrollTo({ top, behavior }: { top: number; behavior: 'smooth' | 'auto'; }) {
    this.getScrollingElement().scrollTo({
      top: top + PAGINATION_DOCUMENT_TOP_MARGIN,
      behavior: 'smooth',
    });
  }

  // Util function for debugging
  setLoggerShouldLog(shouldLog: boolean) {
    this.logger.shouldLog = () => shouldLog;
  }

  // util function for debugging, will allow free scrolling
  setDebugFreeScroll(enabled: boolean) {
    this.debugFreeScroll = enabled;
    if (enabled) {
      this.enableScrollingWithTouch();
    }
  }

  getScrollingElement(): HTMLElement {
    if (!this.document.scrollingElement) {
      throw new ScrollingManagerError('No documentRoot element found');
    }
    return this.document.scrollingElement as HTMLElement;
  }

  blockUpdatingCenterElementForNMS(delay = 2000) {
    this.updatingCenterElementDisabled = true;
    if (this.centerElementUpdatingBlockedTimeout) {
      clearTimeout(this.centerElementUpdatingBlockedTimeout);
    }

    this.centerElementUpdatingBlockedTimeout = setTimeout(() => {
      this.updatingCenterElementDisabled = false;
      this.updateCurrentCenteredElement();
    }, delay);
  }

  disableAllPaginationElements() {
    const allPageDividers = this.document.querySelectorAll<HTMLElement>('.page-divider');
    for (const divider of allPageDividers) {
      divider.classList.add('hide-divider');
    }
    if (!this.topPageSnapshotElement || !this.bottomPageSnapshotElement) {
      throw new ScrollingManagerError('No page content elements found');
    }
    this.topPageSnapshotElement.classList.add('hide-snapshot-content-from-selection');
    this.bottomPageSnapshotElement.classList.add('hide-snapshot-content-from-selection');
  }

  showVisiblePageDividerElements() {
    // TODO: Re-add when ready
    // if (this.window.pagination?.smoothAnimationsDisabled) {
    //   return;
    // }
    // const allDividerBorders = Array.from(this.document.querySelectorAll<HTMLElement>('.divider-border'));
    // if (!allDividerBorders) {
    //   throw new ScrollingManagerError(`No divider borders found`);
    // }
    // for (const divider of allDividerBorders) {
    //   divider.classList.add('divider-shadow');
    // }
  }

  hideVisiblePageDividerElements() {
    // TODO: Re-add when ready
    // const allDividerBorders = Array.from(this.document.querySelectorAll<HTMLElement>('.divider-border'));
    // if (!allDividerBorders) {
    //   throw new ScrollingManagerError(`No divider borders found`);
    // }
    // for (const divider of allDividerBorders) {
    //   divider.classList.remove('divider-shadow');
    // }
  }

  enableAllPaginationElements() {
    const allPageDividers = this.document.querySelectorAll<HTMLElement>('.page-divider');
    for (const divider of allPageDividers) {
      divider.classList.remove('hide-divider');
    }
    if (!this.topPageSnapshotElement || !this.bottomPageSnapshotElement) {
      throw new ScrollingManagerError('No page content elements found');
    }
    this.topPageSnapshotElement.classList.remove('hide-snapshot-content-from-selection');
    this.bottomPageSnapshotElement.classList.remove('hide-snapshot-content-from-selection');
    this.scrollToPage(this.currentPage);
  }

  isRangeABelowRangeB(rangeA: Range, rangeB: Range) {
    // Hacky fix to handle iOS selection behavior:
    // After modifyOnGoingSelectionToGrabPunctuationOnIOS runs, we may have these strings:
    // rangeA: hello  (without leading quotation mark)
    // rangeB: "hello (with leading quotation mark)
    // We need to check if rangeA is below rangeB, accounting for this difference.
    if (rangeB.toString().startsWith('“')) {
      // rangeA.toString() won't have a leading “, that's why we add it here
      const textA = `“${rangeA.toString().trim()}`;
      const textB = rangeB.toString().trim();
      return textB.startsWith(textA) && textB.length > textA.length;
    }

    if (rangeA.startContainer === rangeB.startContainer && rangeA.startOffset === rangeB.startOffset) {
      // This means the end container/offset was moved
      // this means rangeB moved down from the anchor range
      return true;
    }

    return false;
  }

  async onSelectionChange() {
    if (this.currentlyTransitioningPages) {
      return;
    }
    const selection = this.document.getSelection();
    if (selection && !selection.isCollapsed) {
      // We currently are selecting
      if (this.startScrollYOnSelect === null) {
        this.startScrollYOnSelect = this.getScrollingElementTop();
      }
      this.logger.debug(
        `On selection change firing with a selection active, startScrollYOnSelect ${
          this.startScrollYOnSelect
        } getScrollTop: ${this.getScrollingElementTop()}`,
      );
      this.areTouchesDisabledBecauseOfSelection = true;
      // Get the latest Y coord of the selection
      const newRange = selection.getRangeAt(0);
      if (!this.latestSelectionAnchorRange) {
        this.latestSelectionAnchorRange = newRange.cloneRange();
      }
      const anchorRange = this.latestSelectionAnchorRange;
      const isAnchorBelowNewRange = this.isRangeABelowRangeB(anchorRange, newRange);

      if (newRange) {
        if (isAnchorBelowNewRange) {
          this.latestSelectionYCoord =
            this.getScrollingElementTop() + newRange.getBoundingClientRect().bottom;
        } else {
          this.latestSelectionYCoord =
            this.getScrollingElementTop() + newRange.getBoundingClientRect().top;
        }
      }

      this.disableAllPaginationElements();
      return;
    }
    this.logger.debug('On selection change firing with no active selection');
    if (!this.areTouchesDisabledBecauseOfSelection) {
      this.logger.debug('this selection was not related to us');
      // We never made any selection
      this.startScrollYOnSelect = null;
      this.latestSelectionAnchorRange = null;
      return;
    }
    if (this.selectionChangeTimer) {
      clearTimeout(this.selectionChangeTimer);
    }

    // At this point, the selection just ended
    if (this.startScrollYOnSelect !== null && this.latestSelectionYCoord !== null) {
      const selectionEndCoordY = this.latestSelectionYCoord;
      const newPage = this.getPageNumberFromCoordinate(selectionEndCoordY);
      // Selection was made by us
      this.startScrollYOnSelect = null;
      this.latestSelectionAnchorRange = null;
      this.latestSelectionYCoord = null;

      const pageRect = this.getBoundedPageRect(newPage);
      const newScrollTop = newPage <= 0 ? 0 : pageRect.top - TOP_MARGIN;
      this.disableScrollEventsForNMilliseconds();
      // Because the page borders are not enabled yet, don't bother showing them yet
      await this.scrollToCoordSmooth(newScrollTop, 0.2);
      this.onScrollToPageEnd(newPage);
      this.enableAllPaginationElements();
    }

    this.selectionChangeTimer = setTimeout(() => {
      // Timeout so touch end / or other touch events dont register too quickly
      this.areTouchesDisabledBecauseOfSelection = false;
      this.selectionChangeTimer = undefined;
    }, 100);
  }

  updateCurrentHeight() {
    const endOfContentElementResult = document.querySelector<HTMLElement>('#end-of-content');
    if (!endOfContentElementResult) {
      throw new ScrollingManagerError('updateCurrentHeight: No end of content element found');
    }
    this.currentHeight = this.getDocumentTopOfElement(endOfContentElementResult);
  }

  // On Resize handles when the text content resizes
  // We want to take the old center element and scroll to it
  // we also need to recompute all page rect data and re-draw the pages
  async onResize(force = false) {
    if (!this.initialized) {
      // The first resize after we are ready to be initialized
      // This means the fonts were loaded
      await this.init();
      this.initializeCallback();
      return;
    }
    if (!this.documentRoot) {
      throw new ScrollingManagerError('document root not found');
    }
    this.documentRoot.classList.remove('opaque-document-root');
    this.updateCurrentHeight();
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (!newHeight) {
      this.logger.log('failed getting new height for document text content');
      return;
    }
    if (Math.trunc(this.documentTextContentHeight) === Math.trunc(newHeight) && !force) {
      this.logger.log('Resize fired but we are the same height as before');
      return;
    }
    this.documentTextContentHeight = newHeight;
    this.logger.log('resize ', this.documentTextContent?.getBoundingClientRect().height);
    // Block updating the element so random scrolls won't throw us off
    this.blockUpdatingCenterElementForNMS(1000);
    const centeredElementScrollDelta = this.currentCenteredElementInfo?.scrollDelta;
    const centeredElement = this.currentCenteredElementInfo?.element;
    const numberOfComputedPages = this.pageRects.length;

    this.pageRects = [];
    this.computePageRects(numberOfComputedPages);

    this.logger.debug(`OnResize: fired , ${this.getScrollingElementTop()}`);

    if (this.getScrollingElementTop() <= 0) {
      // If we are at the top, we don't need to do anything
      this.scrollToPage(0);
      return;
    }

    if (centeredElementScrollDelta === undefined || !centeredElement) {
      this.logger.debug('OnResize failed due to no center element');
      return;
    }

    /*
      We can't easily rely on scrollDelta here to return to a good position because
      scrollDelta was relative to the previous height of the document
      We need to decide if we want to use the scrollDelta value
      if the scrollDelta value is positive, the centered element was below the page,
      most likely close to center and just scrolling to the element would be better
    */
    let elementOffset = centeredElementScrollDelta;
    if (centeredElementScrollDelta >= 0) {
      elementOffset = 0;
    }
    this.scrollToElement(centeredElement, -elementOffset);
  }

  getDocumentTopOfElement(element: Element) {
    return element.getBoundingClientRect().top + this.getScrollingElementTop();
  }

  isTweetElement(element: Element) {
    if (element.classList.contains(TWEET_CLASS_NAME)) {
      return true;
    }

    let parentElement = element.parentElement;
    for (let i = 0; i < 7; i++) {
      if (!parentElement || parentElement === this.documentTextContent) {
        return false;
      }
      if (parentElement.classList.contains(TWEET_CLASS_NAME)) {
        return true;
      }
      parentElement = parentElement.parentElement;
    }
    return false;
  }

  // Determine if element is allowed to be split across pages
  isElementSplittable(element: Element) {
    if (element.classList.contains(TWEET_CLASS_NAME) || NON_SPLITTABLE_TAGS.has(element.nodeName)) {
      return false;
    }

    if (element.parentElement && this.isTweetElement(element.parentElement)) {
      return false;
    }

    // check if element has only one child and the child is not splittable
    if (element.children.length === 1) {
      return !NON_SPLITTABLE_TAGS.has(element.children[0].nodeName);
    }

    return true;
  }

  computeIntersectingRangeOffsetFromBottomOfPageRect(range: Range, pageRect: PageRect, shouldLogForDebug = false) {
    // This value offsets the bottom coordinate based on where we intersect the element
    let bottomCoordModifier = 0;

    const logger = makeLogger('computeIntersectingRangeOffsetFromBottomOfPageRect', {
      shouldLog: shouldLogForDebug,
    });
    const intersectingRect = { left: 0, right: this.window.innerWidth, top: pageRect.bottom, bottom: pageRect.bottom + 1 };
    logger.log('Check the intersecting rect ', intersectingRect);

    const clientRects = range.getClientRects();
    logger.log('Client rects for intersecting element ', clientRects);
    if (clientRects.length === 0) {
      // somehow we passed a range with no content
      logger.log('No client rects found ');
      return pageRect.bottom - (this.getScrollingElementTop() + range.getBoundingClientRect().top);
    }
    let rectToShow = clientRects[0];
    let clientRectIndex = 0;
      // Find which client rect no longer intersects the line
    while (clientRectIndex < clientRects.length) {
      const clientRect = clientRects[clientRectIndex];
      const absolutelyPositionedRect = {
        top: clientRect.top + this.getScrollingElementTop(),
        bottom: clientRect.bottom + this.getScrollingElementTop(),
        left: clientRect.left,
        right: clientRect.right,
      };
      if (absolutelyPositionedRect.bottom > pageRect.bottom && !areTwoRectsIntersecting(absolutelyPositionedRect, intersectingRect)) {
          // we now found a rect that we are no longer intersecting; or is below the target coordinate
          // take the rect we saw before it
        break;
      }
      rectToShow = clientRect;
      clientRectIndex += 1;
    }

    logger.log('rect to show above border ', rectToShow);
    if (clientRectIndex === 0) {
        // if its the first rect we matched on, just cover up the entire element or range
      bottomCoordModifier = pageRect.bottom - (this.getScrollingElementTop() + range.getBoundingClientRect().top);
    } else {
      bottomCoordModifier = pageRect.bottom - (this.getScrollingElementTop() + rectToShow.bottom);
    }
    logger.log('bottom coord modifier ', bottomCoordModifier);
    return bottomCoordModifier;
  }

  findElementThatIntersectsBottomOfPageRect(
    elementAtBottomParam: Element,
    pageRect: PageRect,
    shouldLogForDebug = false,
  ): [Element, number] {
    const documentRoot = this.documentRoot;
    if (!documentRoot) {
      throw new ScrollingManagerError('Document Text Container not found');
    }
    // Determine where we intersect
    // if we are looking at a bottom element and it intersects, we determine line height
    // and figure out how many lines of text are visible, and move the new bottom divider to show the right amount of lines

    const logger = makeLogger('findElementThatIntersectsBottomOfPageRect', {
      shouldLog: shouldLogForDebug,
    });

    // This value offsets the bottom coordinate based on where we intersect the element
    let bottomCoordModifier = 0;

    let elementThatIntersects = elementAtBottomParam;
    const matcherFunc = (element: Node): element is HTMLElement => {
      return (
        !isTextNode(element) &&
        isHTMLElement(element) &&
          // ignore rw highlight elements
        !element.classList.contains('rw-highlight-icon-wrapper') &&
        !element.classList.contains('rw-highlight-resize-handle') &&
        element.getBoundingClientRect().height > 0 &&
        element.getBoundingClientRect().width > 0 &&
        this.isElementIntersectingAtYCoord(element, pageRect.bottom)
      );
    };


    // If the element has children, we need to find the deepest child that intersects the bottom of the page
    // unless the current element contains direct text nodes
    if (elementThatIntersects.children.length && !this.doesElementContainDirectTextNodes(elementThatIntersects)) {
      const deepestChildThatIntersects = getDeepestWith<HTMLElement>(elementThatIntersects, matcherFunc);
      if (deepestChildThatIntersects && !isTextNode(deepestChildThatIntersects)) {
        logger.log('We found the deepest child node!');
        logger.log(deepestChildThatIntersects);
        elementThatIntersects = deepestChildThatIntersects;
      }
    }

    // if the element has text nodes, we possibly intersect a text node, we should get the line height and move the page boundary to not cut text
    // If the element does not have direct text node children, we probably did not find the deepest element intersecting the page boundary, no need to move the page
    if (this.doesElementContainDirectTextNodes(elementThatIntersects)) {
      logger.log('------ element that intersects has a text node -------------');
      logger.log(elementThatIntersects);

      const range = new Range();
      range.selectNodeContents(elementThatIntersects);

      const intersectingRect = { left: 0, right: this.window.innerWidth, top: pageRect.bottom, bottom: pageRect.bottom + 1 };
      logger.log('Check the intersecting rect ', intersectingRect);
      bottomCoordModifier = this.computeIntersectingRangeOffsetFromBottomOfPageRect(range, pageRect, shouldLogForDebug);
    }
    bottomCoordModifier = Math.round(bottomCoordModifier) + 1;
    logger.log(`final bottom margin modifier ${bottomCoordModifier}`);
    logger.log('-----------------');

    return [elementThatIntersects, bottomCoordModifier];
  }

  /**
   * @param currentPageNum the current page number to compute
   * @param currentPageRect the info of the page right above the pageNUm
   * @return new page rect for pageNum
   */
  computePageRectsForNextPage(
    currentPageNum: number,
    currentPageRect: PageRect,
    shouldLogForDebug = false,
  ): PageRect {
    const logger = makeLogger('computePageRectsForNextPage', { shouldLog: shouldLogForDebug });
    logger.log(`Compute page rect for page ${currentPageNum + 1}, currentPageRect: `, { ...currentPageRect });
    const documentTextContent = this.documentTextContent;
    if (!documentTextContent) {
      throw new ScrollingManagerError('Document Text Container not found');
    }
    const relativeTopOfDocumentTextContent = this.getDocumentTopOfElement(documentTextContent);
    logger.log(`finding element at the bottom of page ${currentPageNum}, currentPageRectBottom: `, currentPageRect.bottom);
    const intersectingRange = this.getRangeAtY(currentPageRect.bottom);
    // IF element At top or bottom is null, that's great, the border height is already determined

    // This value offsets the bottom coordinate based on where we intersect the element
    let bottomCoordModifier = 0;
    let elementAtBottom = null;
    if (intersectingRange) {
      elementAtBottom = this.getFirstElementInRange(intersectingRange);
    }

    logger.log(`Starting with element at bottom of page ${currentPageNum}`, intersectingRange);

    if (elementAtBottom && isHTMLElement(elementAtBottom) && elementAtBottom.getBoundingClientRect().height > 0) {
      [elementAtBottom, bottomCoordModifier] = this.findElementThatIntersectsBottomOfPageRect(
          elementAtBottom,
          currentPageRect,
          shouldLogForDebug,
        );
      logger.log(
          'New element at bottom that intersects the bottom of the page and new page bottom',
          elementAtBottom,
          bottomCoordModifier,
          currentPageRect.bottom,
        );
    } else if (intersectingRange && isTextNode(elementAtBottom) && intersectingRange?.getClientRects().length > 0) {
      // we are intersecting a direct text node, rare but happens
      bottomCoordModifier = this.computeIntersectingRangeOffsetFromBottomOfPageRect(intersectingRange, currentPageRect, shouldLogForDebug);
    }

    currentPageRect.bottom -= bottomCoordModifier;
    let dividerHeight =
      this.pageHeight + BOTTOM_MARGIN - (currentPageRect.bottom - currentPageRect.top);
    logger.log(`divider height for next page is ${dividerHeight}`);
    if (currentPageNum === 0) {
      // uncomment this if we want a divider on the first page
      logger.log(`current page is zero so subtract ${relativeTopOfDocumentTextContent - TOP_MARGIN}`);
      dividerHeight -= relativeTopOfDocumentTextContent - TOP_MARGIN;
      // uncomment this if we want the divider under the first page to be hidden
      // dividerHeight = 0;
    }
    dividerHeight = Math.max(0, dividerHeight + 1);

    if (elementAtBottom && isHTMLElement(elementAtBottom) && !this.isElementSplittable(elementAtBottom)) {
      logger.log(`element at bottom is not splittable`);
      const visibleHeightOfElementAtBottomOnThisPage =
        currentPageRect.bottom - this.getDocumentTopOfElement(elementAtBottom);
      const newPossibleBottom = currentPageRect.bottom - visibleHeightOfElementAtBottomOnThisPage;
      const shouldPushElementToNextPage =
        !this.isTweetElement(elementAtBottom) &&
        newPossibleBottom - currentPageRect.top > MINIMUM_PAGE_HEIGHT;

      logger.log(`should push element to next page? ${shouldPushElementToNextPage}`);
      if (shouldPushElementToNextPage) {
        currentPageRect.bottom = newPossibleBottom;
        dividerHeight += visibleHeightOfElementAtBottomOnThisPage;
      } else {
        dividerHeight = SPLIT_BORDER_HEIGHT;
      }
    }

    // add new border for this page
    return {
      top: currentPageRect.bottom,
      bottom: currentPageRect.bottom + this.pageHeight,
      topPageDividerHeight: dividerHeight,
    };
  }

  buildFirstPageRect() {
    const documentTextContent = this.documentTextContent;
    if (!documentTextContent) {
      throw new ScrollingManagerError('Document Text Container not found');
    }
    const relativeTopOfDocumentTextContent = this.getDocumentTopOfElement(documentTextContent);
    const pageTop = Math.trunc(relativeTopOfDocumentTextContent);
    return {
      top: pageTop,
      bottom: Math.max(pageTop + MINIMUM_PAGE_HEIGHT, Math.trunc(this.pageHeight + TOP_MARGIN)),
      topPageDividerHeight: 0,
    };
  }

  /*
    This is the meat of the paginated manager
    We generate pages up to pageNumToComputeTo,
    if we already have some pages to generate, we reuse their values (memoization essentially)
    otherwise, go through each page, looking at elements intersecting the bottom of the page
    if there are elements, do some math to determine where the page border will be drawn
   */
  computePageRects(pageNum = 0, shouldLogForDebug = false) {
    const logger = makeLogger('computePageRects', { shouldLog: shouldLogForDebug });

    this.updateCurrentHeight();

    const pageNumToReach = pageNum + 3;
    if (shouldLogForDebug) {
      logger.log(`Compute up to page ${pageNumToReach}`);
    }
    if (
      pageNumToReach < this.pageRects.length ||
      this.pageRects.length > 0 &&
        this.pageRects[this.pageRects.length - 1].bottom >= this.currentHeight
    ) {
      logger.log(`No need to compute, we have page margin data up to page ${this.pageRects.length}`);
      return;
    }

    if (this.pageRects.length === 0) {
      this.pageRects = [this.buildFirstPageRect()];
    }

    logger.log(
      `going from ${this.pageRects.length} pages to ${pageNumToReach} pages ${this.currentHeight}`,
    );
    // go through each page
    for (let i = this.pageRects.length - 1; i < pageNumToReach; i++) {
      const currentPageRect = this.pageRects[i];
      // start from previous page margin, compute the start of that page
      // then go down to the bottom of the page and figure out where that start
      const newPageRect = this.computePageRectsForNextPage(i, currentPageRect, shouldLogForDebug);
      if (newPageRect.bottom < currentPageRect.bottom) {
        throw new ScrollingManagerError('We somehow got a smaller coord for next page');
      }

      // add new border for this page
      this.pageRects.push(newPageRect);
      if (newPageRect.bottom >= this.currentHeight) {
        break;
      }
    }
  }

  showDebugStyles(enabled: boolean) {
    if (enabled) {
      this.document.body.classList.add('debug-styles');
    } else {
      this.document.body.classList.remove('debug-styles');
    }
  }

  updateCurrentCenteredElement() {
    this.logger.debug('UpdateCurrentCenteredElement fired');
    if (this.updatingCenterElementDisabled) {
      return;
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('UpdateCurrentCenteredElement Document Text Container not found');
    }
    if (!this.highlightableElements.length) {
      populateFocusableElements(this.documentTextContent, this.highlightableElements);
    }
    const centeredElement = findCenteredElementInViewport(this.highlightableElements, this.window);
    this.logger.debug('updateCurrentCenteredElement ', {
      centeredElement,
      top: centeredElement?.getBoundingClientRect().top,
    });

    // Uncomment for debug purposes
    // const prevElementDebug = this.document.querySelector('.centeredElementDebug');
    // prevElementDebug?.classList.remove('centeredElementDebug');
    // centeredElement?.classList.add('centeredElementDebug');
    // // // this.logger.log(centeredElement);
    // // // this.logger.log('remember to comment me back out');
    if (!centeredElement) {
      return;
    }

    // Scroll delta relative to the current page we are on, -1 because otherwise the offset is the page above
    const elementOffsetFromTopOfPage =
      this.getDocumentTopOfElement(centeredElement as HTMLElement) -
      this.getBoundedPageRect(this.currentPage).top -
      1;

    this.currentCenteredElementInfo = {
      element: centeredElement as HTMLElement,
      scrollDelta: elementOffsetFromTopOfPage,
    };
  }

  exponentialDecayArray(
    initialVelocity: number,
    startDistance: number,
    targetDistance: number,
    totalTime: number,
    steps: number,
  ) {
    // ChatGPT helped me here, generate an array of values that simulate an exponential decay function
    const decayConstant = -Math.log(initialVelocity / (startDistance - targetDistance)) / totalTime;
    const values = [];
    const stepTime = totalTime / steps;

    for (let i = 0; i <= steps; i++) {
      const t = i * stepTime;
      const value = targetDistance + (startDistance - targetDistance) * Math.exp(-decayConstant * t);
      values.push(value);
    }

    return values;
  }

  async fakeSmoothScrollToCoord(targetScrollTop: number, velocity = 1) {
    const currentTransitionPromise = new DeferredPromise<boolean>();
    this.currentTransitionPromises.push(currentTransitionPromise);

    const currentScrollTop = this.getScrollingElementTop();
    const delta = currentScrollTop - targetScrollTop;
    // if delta is positive, we are scrolling up, if delta is negative we are scrolling down
    if (delta === 0) {
      return;
    }
    const steps = Math.ceil(this.scrollTimeForPage / this.scrollStepDelay);
    let expDecayPoints: number[] = [];
    if (currentScrollTop < targetScrollTop) {
      const generatedExpPoints = this.exponentialDecayArray(
        Math.abs(velocity),
        targetScrollTop,
        currentScrollTop,
        this.scrollTimeForPage,
        steps,
      );
      const deltaPoints = generatedExpPoints.map((p) => targetScrollTop - p);
      expDecayPoints = deltaPoints.map((dp) => currentScrollTop + dp);
    } else {
      expDecayPoints = this.exponentialDecayArray(
        Math.abs(velocity),
        currentScrollTop,
        targetScrollTop,
        this.scrollTimeForPage,
        steps,
      );
    }

    let i = 0;
    const move = (d: number, num: number, targetScrollTop: number) => {
      if (currentTransitionPromise.status === 'resolved') {
        return;
      }
      if (d < 0) {
        this.setScrollingElementTop(Math.min(num, targetScrollTop));
      } else {
        this.setScrollingElementTop(Math.max(num, targetScrollTop));
      }
    };
    for (const num of expDecayPoints) {
      requestAnimationFrame(() => {
        move(delta, num, targetScrollTop);
      });
      i += 1;
      if (i > expDecayPoints.length * 0.9) {
        break;
      }
      await delay(this.scrollStepDelay);
    }
    if (this.window.osType === 'ios') {
      this.scrollingElementScrollTo({ top: targetScrollTop, behavior: 'smooth' });
    } else {
      this.setScrollingElementTop(targetScrollTop);
    }
    currentTransitionPromise.resolve(true);
    if (this.currentTransitionPromises.length > 20) {
      this.currentTransitionPromises.slice(this.currentTransitionPromises.length - 2);
    }
  }

  // Take a node and a page number, and fill that node with cloned elements from the page
  populateNodeWithElementsFromPage(contentChildNode: Node, pageNum: number, shouldLogForDebug = false) {
    const logger = makeLogger('populateNodeWithElementsFromPage', { shouldLog: shouldLogForDebug });
    if (pageNum < 0 || pageNum >= this.pageRects.length) {
      logger.warn(
        `Attempted to populate node with elements from page num out of bounds ${pageNum} max: ${this.pageRects.length}`,
      );
      return [];
    }

    const allVisibleRanges = this.getAllRangesInRect(this.pageRects[pageNum], this.pageHeight);
    logger.log(`pageRect for page ${pageNum}`, this.pageRects[pageNum]);
    logger.log(`getting all visible ranges`, allVisibleRanges);

    for (const range of allVisibleRanges) {
      const elementInRange = this.getFirstElementInRange(range);
      logger.log(`found element in range `, elementInRange);
      if (!elementInRange) {
        continue;
      }
      if (isNodeAnHTMLElement(elementInRange)) {
        const clonedNode = elementInRange.cloneNode(true) as HTMLElement;
        clonedNode.id = '';
        const rwHighlights = clonedNode.querySelectorAll('rw-highlight') ?? [];
        for (const highlight of rwHighlights) {
          highlight.setAttribute('data-highlight-id', `none`);
        }
        contentChildNode.appendChild(clonedNode);
      } else if (isTextNode(elementInRange)) {
        const textContent = elementInRange.textContent;
        if (!textContent) {
          continue;
        }
        const textNode = document.createTextNode(textContent);
        contentChildNode.appendChild(textNode);
      }
    }
    return allVisibleRanges;
  }

  // This function takes a page number, and re-creates that page dynamically in either the top or bottom snapshot container
  matchPageSnapshotToPage(element: 'top' | 'bottom', pageNum: number, offset = 0, shouldLogForDebug = false) {
    const logger = makeLogger('matchPageSnapshotToPage', { shouldLog: shouldLogForDebug });
    if (pageNum < 0 || pageNum >= this.pageRects.length) {
      return;
    }
    logger.log(`Matching ${element} to page ${pageNum}`);
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('No document text content found');
    }
    const pageSnapshotElement =
      element === 'top' ? this.topPageSnapshotElement : this.bottomPageSnapshotElement;

    if (!pageSnapshotElement) {
      throw new ScrollingManagerError('No page snapshot elements found');
    }
    // Clear out any old logic that hides this snapshot container
    pageSnapshotElement.classList.remove('hide-snapshot-content');
    // Delete old child
    let childClassList = '';
    if (pageSnapshotElement.children[0]) {
      childClassList = pageSnapshotElement.children[0].classList.toString();
      pageSnapshotElement.removeChild(pageSnapshotElement.children[0]);
    }

    // Create the inner child again
    const contentChild = this.document.createElement('div');
    contentChild.className = childClassList;
    pageSnapshotElement.style.height = '0px';
    pageSnapshotElement.appendChild(contentChild);

    // If the border above this page is non-existent, we don't need this snapshot
    if (element === 'bottom' && this.pageRects[pageNum].topPageDividerHeight === SPLIT_BORDER_HEIGHT) {
      pageSnapshotElement.classList.add('hide-snapshot-content');
      return;
    }
    // If the border below this page is non-existent, we don't need this snapshot
    if (
      element === 'top' &&
      this.pageRects[Math.min(this.pageRects.length - 1, pageNum + 1)].topPageDividerHeight ===
        SPLIT_BORDER_HEIGHT
    ) {
      pageSnapshotElement.classList.add('hide-snapshot-content');
      return;
    }

    const topOfContent = this.getDocumentTopOfElement(this.documentTextContent);
    logger.log(`top of content ${topOfContent}`);
    // get a position relative to the text content
    const tempTopPagePos = pageNum > 0 ? Math.max(0, this.pageRects[pageNum].top - topOfContent) : 0;
    logger.log(`temp top page Pos ${tempTopPagePos}`);

    pageSnapshotElement.setAttribute('current-page', `${pageNum}`);

    // first, make the snapshot the same height as the page
    // then make the snapshot stretch to either above border or below border
    // Take this example
    /*
      --------- Border (Not existent) ------------
          ------- Top Snapshot ------
          -------- Border -------------
         --------current page -------

         The top snapshot needs to be extra high to cover the content that the border otherwise would have covered
         the same idea applies to the bottom snapshot
     */
    // ONE EXCEPTION IS IF THE TOP SNAPSHOT IS FOR PAGE ZERO

    pageSnapshotElement.style.height = `${
      this.pageRects[pageNum].bottom - this.pageRects[pageNum].top + 1
    }px`;
    let topPageHeightModifier = 0;
    if (element === 'top' && pageNum > 0) {
      // If the border above the fold is zero, that means the snapshot won't reach the top of the screen
      // and a bit of true content will peek. Double the height of the snapshot to hide this
      // unlike for the "bottom" element the top is a bit tricky
      // we will need to move the snapshot element up by the same amount we stretched it
      // this all happens lower down in this file
      const borderAboveThisPageHeight = this.getBoundedPageRect(pageNum).topPageDividerHeight;
      if (borderAboveThisPageHeight === 0) {
        topPageHeightModifier = this.pageHeight;
      }
      pageSnapshotElement.style.height = `${
        this.pageRects[pageNum].bottom - this.pageRects[pageNum].top + topPageHeightModifier + 1
      }px`;
    } else if (element === 'bottom') {
      const borderBelowThisPageHeight = this.getBoundedPageRect(pageNum + 1).topPageDividerHeight;
      // If the border below the fold is zero, that means the snapshot wont reach the bottom of the screen
      // and a bit of true content will peek. Double the height of the snapshot to hide this
      const modifier = borderBelowThisPageHeight === 0 ? 2 : 1;
      pageSnapshotElement.style.height = `${
        (this.pageRects[pageNum].bottom - this.pageRects[pageNum].top) * modifier + 1
      }px`;
    }
    // move the snapshot to the start of the page we are simulating
    // Here is where we move the top snapshot element up by however much we increased its height by
    pageSnapshotElement.style.top = `${tempTopPagePos + offset - topPageHeightModifier}px`;

    const allRangesInSnapshot = this.populateNodeWithElementsFromPage(contentChild, pageNum, shouldLogForDebug);

    let childOffset = 0;
    // Now that we created all the children, we actually need to move the content inside the snapshot by the offset provided
    if (allRangesInSnapshot.length > 0) {
      // const firstVisibleElementRect = this.getEfficientClientRectFromRange(allRangesInSnapshot[0]);
      let firstVisibleElementRect = this.getEfficientClientRectFromRange(allRangesInSnapshot[0]);
      // get the first truly visible range
      // the reason we need to do this is that sometimes invisible elements might render incorrectly
      // in the root document vs the snapshot, and create incorrect boundary calculations
      // almost always though this loop will terminate after the first tick
      for (const range of allRangesInSnapshot) {
        const rangeRect = this.getEfficientClientRectFromRange(range);
        if (rangeRect.height > 0) {
          firstVisibleElementRect = rangeRect;
          logger.log(
            `First visible element range in real document`,
            this.getFirstElementInRange(range),
            range,
            firstVisibleElementRect.top,
          );
          break;
        }
      }


      let firstVisibleChildNodeInContent = contentChild.childNodes[0];
      for (const child of contentChild.childNodes) {
        const childRange: Range = new Range();
        childRange.selectNode(child);
        const rangeRect = this.getEfficientClientRectFromRange(childRange);
        if (rangeRect.height > 0) {
          firstVisibleChildNodeInContent = child;
          break;
        }
      }
      const visibleSnapshotChildRange: Range = new Range();
      visibleSnapshotChildRange.selectNode(firstVisibleChildNodeInContent);
      const firstVisibleSnapshotChildRect = this.getEfficientClientRectFromRange(visibleSnapshotChildRange);
      logger.log(
        `First visible element and range in snapshot`,
        firstVisibleChildNodeInContent,
        visibleSnapshotChildRange,
        firstVisibleSnapshotChildRect.top,
      );

      childOffset =
        firstVisibleElementRect.top -
        firstVisibleSnapshotChildRect.top;
      logger.log(`initial child offset comp ${childOffset}`);

      const pageBelow = Math.min(this.pageRects.length, pageNum + 1);
      logger.log(`The page below is ${pageBelow}`, this.pageRects[pageBelow]);
      const topElementOffset = this.getBoundedPageRect(pageBelow).topPageDividerHeight;
      const bottomElementOffset = this.getBoundedPageRect(pageNum).topPageDividerHeight;

      if (element === 'top') {
        // If we are simulating the top element, we want to move the content UP by the height of the border of the page below
        childOffset -= topElementOffset;
        // if the border is empty, no need for this, slightly redundant code, technically we shouldnt reach here
        if (topElementOffset === 0) {
          pageSnapshotElement.classList.add('hide-snapshot-content');
        }
        logger.log(`Top Element offset ${topElementOffset}`);
      } else {
        // If we are simulating the bottom element, we want to move the content down by the height of the border of the page above
        childOffset += bottomElementOffset;
        // if the border is empty, no need for this, slightly redundant code, technically we shouldnt reach here
        if (bottomElementOffset === 0) {
          pageSnapshotElement.classList.add('hide-snapshot-content');
        }
        logger.log(`bottom Element offset ${bottomElementOffset}`);
      }
    }
    const firstChild = pageSnapshotElement.childNodes[0] as HTMLElement;
    firstChild.style.top = `${childOffset}px`;

    return tempTopPagePos + offset;
  }

  refreshPageSnapshotsForPage(newPage: number) {
    // re-simulate top and bottom content snapshots
    // Move the top content snapshot above this current page and offset it by the border size at the top
    if (newPage - 1 >= 0) {
      this.matchPageSnapshotToPage(
        'top',
        newPage - 1,
        -this.getBoundedPageRect(newPage).topPageDividerHeight,
      );
    }
    // Move the bottom content snapshot below this current page and offset it by the border size at the bottom of this page
    if (newPage + 1 < this.pageRects.length) {
      this.matchPageSnapshotToPage(
        'bottom',
        newPage + 1,
        this.getBoundedPageRect(newPage + 1).topPageDividerHeight,
      );
    }
  }

  refreshPageSnapshotsForCurrentPage() {
    this.refreshPageSnapshotsForPage(this.currentPage);
  }

  getBoundedPageNum(pageNum: number) {
    if (this.pageRects.length - 1 < pageNum) {
      this.computePageRects(pageNum + 3);
    }

    // return a value from either zero to up to pageRects; we should have all the pageRects computed
    // up to this pageNum, if the page num was greater than all the pages we could compute, return the last page
    return Math.min(Math.max(0, pageNum), this.pageRects.length - 1);
  }

  getBoundedPageRect(pageNum: number) {
    if (this.pageRects.length <= pageNum) {
      this.computePageRects(pageNum);
    }

    if (pageNum < 0) {
      return this.pageRects[0];
    }

    // At this point we should have computed all the pages up to pageNum or max pages
    // if pageNum is greater than max page number, return the last page
    const pageNumToReturn = Math.min(pageNum, this.pageRects.length - 1);
    return this.pageRects[pageNumToReturn];
  }

  movePageBorder(borderId: string, pageRect: PageRect, newTop: number, shouldHideBorder: boolean) {
    const pageDivider = this.document.querySelector<HTMLElement>(`#page-divider-${borderId}`);

    if (!pageDivider) {
      throw new ScrollingManagerError(`No element with ID #page-divider-${borderId} found`);
    }
    pageDivider.style.top = `${newTop}px`;
    if (pageRect.topPageDividerHeight === 0 || shouldHideBorder) {
      pageDivider.classList.add('non-splittable-divider');
    } else {
      pageDivider.classList.remove('non-splittable-divider');
      pageDivider.style.height = `${pageRect.topPageDividerHeight + 1}px`;
    }
  }

  onScrollToPageEnd(newPage: number) {
    this.logger.log(`On scroll to page end, newPage: ${newPage}`);

    this.currentPage = newPage;
    this.hideVisiblePageDividerElements();

    if (!this.bottomPageSnapshotElement || !this.topPageSnapshotElement || !this.headerContainer) {
      throw new ScrollingManagerError(
        '[ScrollToPage] No page snapshot elements or header element found',
      );
    }

    const previousPageRect = this.getBoundedPageRect(newPage - 1);
    const pageRect = this.getBoundedPageRect(newPage);
    const nextPageRect = this.getBoundedPageRect(newPage + 1);
    const nextNextPageRect = this.getBoundedPageRect(newPage + 2);

    this.movePageBorder(
      'border-above-previous-page',
      previousPageRect,
      previousPageRect.top - pageRect.topPageDividerHeight - previousPageRect.topPageDividerHeight,
      newPage === 0,
    );
    this.movePageBorder(
      'border-above-current-page',
      pageRect,
      pageRect.top - pageRect.topPageDividerHeight,
      false,
    );
    this.movePageBorder(
      'border-below-current-page',
      nextPageRect,
      nextPageRect.top,
      newPage >= this.pageRects.length - 1,
    );
    this.movePageBorder(
      'border-below-next-page',
      nextNextPageRect,
      nextPageRect.bottom + nextPageRect.topPageDividerHeight,
      newPage >= this.pageRects.length - 1,
    );

    if (newPage === 0) {
      this.headerContainer.style.top = '0px';
    } else {
      const secondPageRect = this.getBoundedPageRect(1);
      // offset the header by the size of the first pages bottom border
      this.headerContainer.style.top = `${-secondPageRect.topPageDividerHeight}px`;
    }

    const newScrollTop = newPage <= 0 ? 0 : pageRect.top - TOP_MARGIN;
    this.setScrollingElementTop(newScrollTop);

    if (newPage <= 0) {
      this.topPageSnapshotElement.classList.add('hide-snapshot-content');
    }
    if (newPage >= this.pageRects.length - 1) {
      animateEndOfReadingButton(0);
      this.bottomPageSnapshotElement.classList.add('hide-snapshot-content');
    } else {
      this.bottomPageSnapshotElement.classList.remove('hide-snapshot-content');
    }

    // re-simulate top and bottom content snapshots
    this.refreshPageSnapshotsForPage(newPage);

    this.enableScrollingWithTouch();
    this.updateCurrentCenteredElement();
    this.currentlyTransitioningPages = false;

    this.onScrollEnd();

    for (const func of this.scrollListeners) {
      func();
    }
  }

  onScrollToPageStart(newPage: number) {
    if (!this.bottomPageSnapshotElement || !this.topPageSnapshotElement) {
      throw new ScrollingManagerError('[ScrollToPage] No page snapshot elements found');
    }
    this.disableScrollingWithTouch();
    this.logger.log(`We want to scroll to page ${newPage}`);
    this.computePageRects(newPage);

    if (newPage > 1) {
      this.headerImageContainer?.classList.add('hide-snapshot-content');
    } else {
      this.headerImageContainer?.classList.remove('hide-snapshot-content');
    }
  }

  emitScrollStartEvent() {
    if (this.scrollingEventsDisabled) {
      return;
    }
    const { clientHeight } = this.getScrollingElement();
    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();

    this.initialPageOnScrollStart = this.currentPage;

    this.window.portalGateToForeground.emit('scroll_start', {
      currentScrollValue: this.getScrollingElementTop(),
      maxScrollValue: this.getScrollingElementMaxScroll(),
      clientScrollableWindowSize: clientHeight,
      serializedPosition: serializedPositionInfo?.serializedPosition,
      serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      readingPositionScrollTop: this.getReadingPositionScrollTop(),
    });
  }

  scrollToPage(newPageParam: number): void {
    if (this.debugFreeScroll) {
      return;
    }

    // Since we didn't trigger this via a scroll, we should fire these events here
    this.emitScrollStartEvent();

    const currentScrollPos = this.getScrollingElementTop();

    const newPage = this.getBoundedPageNum(newPageParam);

    const currentPage = this.currentPage;
    this.currentPage = newPage;

    const pageRect = this.getBoundedPageRect(newPage);
    const newScrollTop = newPage <= 0 ? 0 : pageRect.top - TOP_MARGIN;
    this.logger.log(`Scroll to page ${newPage}`, { newScrollTop, pageRect });

    const direction = currentPage < newPage ? 'down' : 'up';
    let initialScrollTarget = 0;
    if (direction === 'down') {
      initialScrollTarget = newScrollTop;
    } else {
      initialScrollTarget = Math.max(0, newScrollTop);
    }

    if (currentScrollPos === initialScrollTarget) {
      this.onScrollToPageEnd(this.currentPage);
      return;
    }

    this.onScrollToPageStart(newPage);
    this.setScrollingElementTop(initialScrollTarget);
    this.onScrollToPageEnd(newPage);
  }

  async scrollToCoordSmooth(yCoord: number, initialVelocity = 0) {
    const promises = [];
    if (this.window.osType === 'ios') {
      this.scrollingElementScrollTo({ top: yCoord, behavior: 'smooth' });
      if (this.hapticsOnScrollEnabled) {
        setTimeout(() => {
          this.window.portalGateToForeground.emit('haptics_feedback');
        }, this.iosScrollDelay - this.hapticsOnScrollTimeModifier);
      }
      await delay(this.iosScrollDelay);
    } else {
      promises.push(this.fakeSmoothScrollToCoord(yCoord, initialVelocity));
      await Promise.all(promises);
    }
  }


  async scrollToPageSmooth(newPageParam: number, initialVelocity = 0): Promise<void> {
    if (this.debugFreeScroll || this.pageRects.length === 0) {
      return;
    }
    if (!this.headerImageContainer) {
      throw new ScrollingManagerError('[ScrollToPage] No header image container found');
    }
    if (!this.bottomPageSnapshotElement || !this.topPageSnapshotElement) {
      throw new ScrollingManagerError('[ScrollToPage] No page snapshot elements found');
    }
    if (!this.documentRoot) {
      throw new ScrollingManagerError('[ScrollToPage] No document root found');
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('[ScrollToPage] No document text content found');
    }
    if (!this.headerContainer) {
      throw new ScrollingManagerError('[ScrollToPage] No header container found');
    }
    const currentPage = this.currentPage;
    const newPage = this.getBoundedPageNum(newPageParam);

    if (this.smoothAnimationsDisabled) {
      for (const promise of this.currentTransitionPromises) {
        try {
          promise.resolve(true);
        } catch (e) {

          /* empty */
        }
      }
      return this.scrollToPage(newPage);
    }

    await Promise.all(this.currentTransitionPromises);

    this.onScrollToPageStart(newPage);

    const scrollingToSamePage = currentPage === newPage;
    this.logger.log(`Scroll to page ${newPage} currentPage ${currentPage} smooth `);

    this.currentlyTransitioningPages = true;

    this.currentPage = newPage;
    const currentPageRect = this.getBoundedPageRect(newPage);
    const newScrollTop = newPage <= 0 ? 0 : currentPageRect.top - TOP_MARGIN;

    const direction = currentPage < newPage ? 'down' : 'up';

    const pageBelow = this.getBoundedPageNum(newPage + 1);
    const pageBelowRect = this.getBoundedPageRect(pageBelow);
    const topElementOffset = pageBelowRect.topPageDividerHeight;
    const bottomElementOffset = currentPageRect.topPageDividerHeight;

    let initialScrollTarget = newScrollTop;
    if (direction === 'down') {
      if (currentPageRect.topPageDividerHeight !== SPLIT_BORDER_HEIGHT) {
        initialScrollTarget = newScrollTop + bottomElementOffset + this.pageScrollOffset;
      }
    } else {
      initialScrollTarget = newScrollTop - topElementOffset - this.pageScrollOffset;
    }

    // If we are not on the first page, move the border up a bit
    // so when we reach the first page, its correctly offset until we finish scrolling to first page
    if (currentPage !== 0) {
      const secondPageRect = this.getBoundedPageRect(1);
      // offset the header by the size of the first pages bottom border
      this.headerContainer.style.top = `${-secondPageRect.topPageDividerHeight}px`;
    }

    if (scrollingToSamePage) {
      initialScrollTarget = newScrollTop;
    }

    await this.scrollToCoordSmooth(initialScrollTarget, initialVelocity);

    // ON SCROLL END
    this.onScrollToPageEnd(newPage);
  }


  onScrollStart() {
    this.emitScrollStartEvent();
  }

  onScroll() {
    if (this.scrollingEventsDisabled) {
      return;
    }
    const { clientHeight } = this.getScrollingElement();

    const currentScrollValue = this.getScrollingElementTop();
    this.currentScrollValue = currentScrollValue;

    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
    this.window.portalGateToForeground.emit('scroll', {
      currentScrollValue,
      maxScrollValue: this.getScrollingElementMaxScroll(),
      clientScrollableWindowSize: clientHeight,
      serializedPosition: serializedPositionInfo?.serializedPosition,
      serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      readingPositionScrollTop: this.getReadingPositionScrollTop(),
    });
  }

  onScrollEnd() {
    if (this.scrollingEventsDisabled) {
      return;
    }
    const { clientHeight } = this.getScrollingElement();

    const currentScrollValue = this.getScrollingElementTop();
    this.currentScrollValue = currentScrollValue;

    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
    this.window.portalGateToForeground.emit('scroll_end', {
      currentScrollValue,
      maxScrollValue: this.getScrollingElementMaxScroll(),
      clientScrollableWindowSize: clientHeight,
      serializedPosition: serializedPositionInfo?.serializedPosition,
      serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      readingPositionScrollTop: this.getReadingPositionScrollTop(),
    });
  }

  scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    this.logger.log('Scroll To Reading Position ', readingPosition);
    if (readingPosition.serializedPosition) {
      try {
        this.scrollToSerializedPosition(
          readingPosition.serializedPosition,
          readingPosition.mobileSerializedPositionElementVerticalOffset ?? 0,
        );
      } catch (e) {
        if (readingPosition.scrollDepth !== null) {
          this.scrollToPercentOfViewport(readingPosition.scrollDepth);
        }
      }
    } else if (readingPosition.scrollDepth !== null) {
      this.scrollToPercentOfViewport(readingPosition.scrollDepth);
    }
    this.updateCurrentCenteredElement();
    this.startTouchY = null;
    this.enableScrollingWithTouch();
  }

  isDocumentScrolledToBeginning(): boolean {
    return this.getScrollingElementTop() < 100;
  }

  scrollToElement(element: HTMLElement, offset = 0, behavior: 'smooth' | 'auto' = 'auto') {
    this.logger.log(`Scroll to element with offset ${offset}`, element);
    const y = this.getDocumentTopOfElement(element);
    this.logger.log(`The Y of element is ${y}`);
    this.setScrollingElementTop(y + offset);
    this.currentPage = this.getPageNumberFromCoordinate(y + offset);
    this.scrollToPage(this.currentPage);
  }

  scrollToRect(rect: DOMRect, offset = 0) {
    const newTop = Math.floor(this.getScrollingElementTop() + rect.top) + offset;
    this.logger.log(`Scroll to rect ${newTop}`);
    const newPageNumber = this.getPageNumberFromCoordinate(newTop);
    this.scrollToPage(newPageNumber);
  }

  scrollToTop() {}

  // It's important to note that pageRect is relative to the entire document
  // thus CSS top positions and pageRect might not always align as CSS top positions are relative to the coordinates of their parents
  getPageNumberFromCoordinate(yCoord: number) {
    this.logger.log(`getPageNumberFromCoordinate ${yCoord}`);
    const firstPageRect = this.getBoundedPageRect(0);
    if (firstPageRect.top > yCoord) {
      return 0;
    }

    // If we were to scroll to this ycoord, what page is most visible?

    for (let i = 0; i < this.pageRects.length; i++) {
      const pageRect = this.getBoundedPageRect(i);
      if (pageRect.top <= yCoord && pageRect.bottom >= yCoord) {
        this.logger.log(`found page ${i}`);
        return i;
      }
    }
    this.logger.log(
      'We tried to find the page coordinate but we ran out of pages, probably need to make more pages?',
    );
    this.logger.log(`number of pages computed: ${this.pageRects.length}`);
    this.logger.log('Estimate how many more pages we need ');
    this.logger.log(
      `yCoord: ${yCoord} pageHeight: ${this.pageHeight} page num to generate: ${Math.ceil(
        yCoord / (this.pageHeight / 2),
      )}`,
    );
    // TODO: This process is pretty error prone, we kind of assume how many pages we need to compute
    // before we find the coord in question, however the better way would be to compute pages UNITL we find the coord
    this.computePageRects(Math.ceil(yCoord / (this.pageHeight / 2)));

    for (let i = 0; i < this.pageRects.length; i++) {
      if (this.pageRects[i].top - TOP_MARGIN > yCoord) {
        this.logger.log(`found page  ${i - 1}`);
        return i - 1;
      }
    }
    this.logger.log(` here are all the page rects ${this.pageRects}`);
    this.logger.log(`found last page  ${this.pageRects.length - 1}`);
    return this.pageRects.length - 1;
  }

  scrollToPercentOfViewport(percent: number, animated = false, disableEvents = false) {
    this.logger.log('scroll to percent of viewport');
    if (disableEvents) {
      this.disableScrollEventsForNMilliseconds();
    }
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const { scrollHeight } = this.getScrollingElement();
    const newScrollTop = scrollHeight * percent;
    const newPageNumber = this.getPageNumberFromCoordinate(newScrollTop);
    this.logger.log(`Scroll to page ${newPageNumber}`);
    this.scrollToPage(newPageNumber);
  }

  handleScrollFromHref() {
    const newPage = this.getPageNumberFromCoordinate(this.getScrollingElementTop());
    this.scrollToPage(newPage);
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    if (this.ttsAutoScrollingEnabled) {
      // The offset helps make sure the TTS element stays on the right page
      this.scrollToRect(rect, rect.height - 2);
    }
  }

  returnToReadingPosition() {
    if (this.readingPosition) {
      this.scrollToReadingPosition(this.readingPosition);
    }
    setTimeout(() => {
      const scrollTop = this.getScrollingElementTop();
      if (!this.documentRoot) {
        throw new ScrollingManagerError('No document root found');
      }
      const { clientHeight } = this.getScrollingElement();
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('return_to_reading_position', {
        currentScrollValue: scrollTop,
        maxScrollValue: this.getScrollingElementMaxScroll(),
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
        readingPositionScrollTop: this.getReadingPositionScrollTop(),
      });
    }, 4);
  }

  disableScrollingWithTouch() {
    this.logger.log('Disable scroll with touch');
    if (this.debugFreeScroll) {
      return;
    }
    this.document.body.classList.add('disable-scroll');
    if (this.enableScrollTimeout) {
      clearTimeout(this.enableScrollTimeout);
    }
  }

  enableScrollingWithTouch() {
    if (this.smoothAnimationsDisabled) {
      return;
    }
    if (this.enableScrollTimeout) {
      clearTimeout(this.enableScrollTimeout);
    }
    this.logger.log('We want to re-enable scroll with touch in a sec');
    this.enableScrollTimeout = setTimeout(() => {
      // We are still holding the screen if this was necessary to fire, dont
      if (this.startTouchY !== null) {
        return;
      }
      this.document.body.classList.remove('disable-scroll');
      this.logger.log('enable scroll with touch');
    }, 100);
  }

  onTouchStart(e: TouchEvent) {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('onTouchStart, Document root not found');
    }
    if (this.smoothAnimationsDisabled) {
      this.disableScrollingWithTouch();
    }
    if (e.touches.length === 1) {
      this.showVisiblePageDividerElements();
      this.scrollStartTime = nowTimestamp();
      this.startPageOnTouch = this.currentPage;
      this.startTouchY = e.touches[0].pageY;
      this.endTouchY = this.startTouchY;
      this.touchYDirection = 0;

      this.startTouchClientY = e.touches[0].clientY;
      this.endTouchClientY = e.touches[0].clientY;
      this.throttledTouchClientY = e.touches[0].clientY;

      this.startTouchX = e.touches[0].screenX;
      this.endTouchX = this.startTouchX;

      this.emitScrollStartEvent();
    }
  }

  onTouchMove(e: TouchEvent) {
    if (this.startTouchY === null) {
      // should never happen
      return;
    }
    this.currentlyScrollingBecauseOfTouch = true;
    if (this.touchMoveThrottle === 0) {
      this.window.portalGateToForeground.emit('touch_move');
    }
    const newClientY = e.touches[0].clientY;

    if (this.areTouchesDisabledBecauseOfSelection) {
      this.endTouchY = e.touches[0].pageY;
      this.endTouchClientY = newClientY;
      return;
    }

    this.touchMoveThrottle += 1;
    if (this.touchMoveThrottle > 2) {
      this.logger.log(`newTouch ${newClientY} oldTouch: ${this.throttledTouchClientY} `);
      this.throttledTouchClientY = newClientY;
      this.touchMoveThrottle = 0;
    }
    const touchYDelta = Math.abs(this.throttledTouchClientY - newClientY);
    if (touchYDelta > 0) {
      const direction = (this.throttledTouchClientY - newClientY) / touchYDelta;
      if (this.touchYDirection !== direction) {
        this.logger.log(`Direction changed!! new startY ${newClientY}`);
        this.scrollStartTime = nowTimestamp();
      }
      this.touchYDirection = direction;
    }
    this.endTouchY = e.touches[0].pageY;
    this.endTouchClientY = newClientY;
    this.endTouchX = e.touches[0].screenX;
    if (this.enableScrollTimeout) {
      clearTimeout(this.enableScrollTimeout);
    }
  }

  // return true if an element should not allow a scroll event to happen if it is clicked on a margin
  isTouchTargetClickableOnMargins(touchTarget: Node | undefined | null) {
    const hasATagAncestor =
      touchTarget && touchTarget instanceof HTMLElement && getATagAncestor(touchTarget);
    return (
      hasATagAncestor ||
      isHTMLElement(touchTarget) &&
        (CLICKABLE_TAGS_THROUGH_PAGINATION_MARGINS.has(touchTarget.nodeName) ||
          touchTarget.classList.contains('tts-button-text'))
    );
  }

  onTouchEnd(e: TouchEvent) {
    if (this.startTouchY === null || this.startPageOnTouch === null) {
      return;
    }
    this.hideVisiblePageDividerElements();
    this.currentlyScrollingBecauseOfTouch = false;
    const currentPage = this.startPageOnTouch;
    this.startPageOnTouch = null;
    this.startTouchY = null;
    const currentTime = nowTimestamp();
    const timeDelta = currentTime - this.scrollStartTime;
    this.scrollStartTime = 0;
    const yTouchDelta = this.startTouchClientY - this.endTouchClientY;
    // Default to slightly less than threshold
    let verticalDelta = (this.verticalSwipeDistanceMinimumThreshold - 2) * this.touchYDirection;

    if (this.touchYDirection < 0 && yTouchDelta < 0 || this.touchYDirection > 0 && yTouchDelta > 0) {
      // we need to check that the direction we ended up swiping also reflects the amount of screen drag we created
      // if we dragged up, touchYDirection is -1, and if directionalTouchDelta is negative that means the screen moved up from the start touch spot
      // if this wasnt true, then we did not move enough to justify an entire page swipe
      verticalDelta = yTouchDelta;
    }

    const horizontalDelta = Math.abs(this.startTouchX - this.endTouchX);
    const isHorizontalSwipe =
      horizontalDelta > this.horizontalSwipeDistanceThreshold &&
      horizontalDelta > verticalDelta &&
      Math.abs(verticalDelta) < this.verticalSwipeDistanceConclusiveThreshold;

    const velocity = Math.abs(verticalDelta / timeDelta);

    this.logger.log(
      `On touch end - startY: ${this.startTouchClientY} end: ${this.endTouchClientY} touchDelta ${verticalDelta} direction ${this.touchYDirection} velocity ${velocity} timeDelta ${timeDelta} horizontal delta: ${horizontalDelta} isHorizontalSwipe: ${isHorizontalSwipe}`,
    );

    const isTapOnScreen =
      timeDelta < this.tapTimeThreshold && Math.abs(verticalDelta) < 2 && Math.abs(velocity) < 0.05;
    this.logger.log('IS TAP ON SCREEN ', isTapOnScreen);

    if (isTapOnScreen) {
      let target = null;
      if (e.changedTouches.length > 0) {
        target = e.changedTouches[0].target as Node;
      }
      if (
        this.isTouchTargetClickableOnMargins(target) ||
        this.isTouchTargetClickableOnMargins(target?.parentElement)
      ) {
        this.hideVisiblePageDividerElements();
        return;
      }
      if (this.endTouchX <= this.leftClickAreaWidth) {
        // Press on left side
        this.scrollToPage(currentPage - 1);
        this.window.portalGateToForeground.emit('touch_move');
        this.window.portalGateToForeground.emit('haptics_feedback');
        e.preventDefault();
        e.stopPropagation();
        return;
      } else if (this.endTouchX >= this.rightClickAreaWidth) {
        // Press on right side
        this.scrollToPage(currentPage + 1);
        this.window.portalGateToForeground.emit('touch_move');
        this.window.portalGateToForeground.emit('haptics_feedback');
        e.preventDefault();
        e.stopPropagation();
        return;
      } else {
        // Code that handles a general press on the center of screen
        this.hideVisiblePageDividerElements();
        // If we click on a tag that has some kind of click handler, handle that
        if (target && isHTMLElement(target) && CLICKABLE_TAGS.has(target.nodeName)) {
          return;
        }

        this.window.portalGateToForeground.emit('root-clicked');
        return;
      }
    }

    if (this.areTouchesDisabledBecauseOfSelection) {
      this.hideVisiblePageDividerElements();
      return;
    }
    // Here we actually commit the scroll
    let newX: number = currentPage;
    if (
      !isHorizontalSwipe &&
      (verticalDelta > this.verticalSwipeDistanceMinimumThreshold ||
        verticalDelta > 0 && velocity > this.velocityThreshold)
    ) {
      newX = currentPage + 1;
    } else if (
      !isHorizontalSwipe &&
      (verticalDelta < -this.verticalSwipeDistanceMinimumThreshold ||
        verticalDelta < 0 && velocity > this.velocityThreshold)
    ) {
      newX = currentPage - 1;
    } else {
      newX = currentPage;
    }
    this.scrollToPageSmooth(newX, velocity);
  }

  getContentHeight() {
    return document.querySelector('body')?.scrollHeight;
  }
}
