import throttle from 'lodash/throttle';
import React, { memo, useRef, useState } from 'react';

import styles from './ResizeHandle.module.css';

function ResizeHandle({
  onHandleMove,
  onStopResizing,
  onStartResizing,
  wrapperClassName = '',
  handleClassName = '',
}: {
  onHandleMove: (delta: number) => void;
  onStopResizing?: (finalDelta: number | null) => void;
  onStartResizing?: () => void;
  wrapperClassName?: string;
  handleClassName?: string;
}) {
  const lastPositionRef = useRef<number | null>(null);
  const totalDeltaRef = useRef<number>(0);
  const [isDragging, setIsDragging] = useState(false);

  const resize = (clientY: number) => {
    if (lastPositionRef.current === null) {
      lastPositionRef.current = clientY;
      return;
    }

    const delta = clientY - lastPositionRef.current;
    totalDeltaRef.current += delta;
    lastPositionRef.current = clientY;
    onHandleMove(delta);
  };

  const throttleResize = throttle((e: MouseEvent | TouchEvent) => {
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    resize(clientY);
  }, 20, { trailing: false });

  const stopResizing = () => {
    setIsDragging(false);
    document.removeEventListener('mousemove', throttleResize);
    document.removeEventListener('mouseup', stopResizing);
    document.removeEventListener('touchmove', throttleResize);
    document.removeEventListener('touchend', stopResizing);
    if (onStopResizing) {
      onStopResizing(totalDeltaRef.current);
      lastPositionRef.current = null;
      totalDeltaRef.current = 0;
    }
  };

  const startResizing = (e: React.MouseEvent | React.TouchEvent) => {
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    lastPositionRef.current = clientY;
    totalDeltaRef.current = 0;
    document.addEventListener('mousemove', throttleResize);
    document.addEventListener('mouseup', stopResizing);
    document.addEventListener('touchmove', throttleResize);
    document.addEventListener('touchend', stopResizing);
    onStartResizing?.();
    setIsDragging(true);
  };

  const handleWrapperClassNames = [
    wrapperClassName,
    styles.handleWrapper,
    isDragging && styles.isDragging,
  ].filter(Boolean).join(' ');

  const handleClassNames = [
    handleClassName,
    styles.handle,
  ].filter(Boolean).join(' ');

  return (
    <div
      onMouseDown={startResizing}
      onTouchStart={startResizing}
      onMouseUp={stopResizing}
      onTouchEnd={stopResizing}
      role="button"
      tabIndex={0}
      className={handleWrapperClassNames}
    >
      <div className={handleClassNames} />
    </div>
  );
}

export default memo(ResizeHandle);
