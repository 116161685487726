import { arrayBufferToBase64 } from './bytes';

export function createDataUrl(filename: string, data: ArrayBuffer): string {
  const base64Data = arrayBufferToBase64(data);
  const [, fileExtension] = filename.split('.');
  let mimeType;
  switch (fileExtension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      mimeType = 'image/jpeg';
      break;
    case 'png':
      mimeType = 'image/png';
      break;
    case 'gif':
      mimeType = 'image/gif';
      break;
    case 'webp':
      mimeType = 'image/webp';
      break;
    case 'svg':
      mimeType = 'image/svg+xml';
      break;
    case 'bmp':
      mimeType = 'image/bmp';
      break;
    case 'ico':
      mimeType = 'image/x-icon';
      break;
    case 'tiff':
    case 'tif':
      mimeType = 'image/tiff';
      break;
    default:
      mimeType = 'application/octet-stream';
  }

  return `data:${mimeType};base64,${base64Data}`;
}
