import isEqual from 'lodash/isEqual';

import type { ChunkSanitizationOptions, WebviewDocumentChunk } from '../../types/chunkedDocuments';
import type { LenientWindow } from '../../types/LenientWindow';
import { DeferredPromise } from '../../utils/DeferredPromise';
import { isDevOrTest } from '../../utils/environment';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import makeLogger from '../../utils/makeLogger';
import { rwSanitizeHtml } from '../../utils/rwSanitizeHtml';
// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../portalGates/contentFrame/from/reactNativeWebview';
import {
  convertCanonicalPositionToChunkAware,
  isChunkedDocumentContentRoot,
} from '../utils/locationSerialization/chunked';
import eventEmitter from './eventEmitter';

declare let window: LenientWindow;

const logger = makeLogger(__filename, { shouldLog: true });

function triggerContentUnloadForChunk(chunkId: string) {
  portalGateToForeground.emit('chunk-exited-viewing-window', chunkId);
}

function triggerContentLoadForChunk(chunkId: string) {
  portalGateToForeground.emit('chunk-entered-viewing-window', chunkId);
}

class ChunkContainer {
  readonly chunkId: string;
  readonly index: number;
  readonly element: HTMLDivElement;
  readonly chunkChildNodeCount: number;

  hasContent: boolean;

  private _sanitizationOptions: ChunkSanitizationOptions;
  private _entryIntersectionObserver: IntersectionObserver | null = null;
  private _exitIntersectionObserver: IntersectionObserver | null = null;

  constructor(chunk: WebviewDocumentChunk, sanitizationOptions: ChunkSanitizationOptions) {
    this._sanitizationOptions = sanitizationOptions;
    this.chunkId = chunk.id;
    this.index = chunk.index;
    this.chunkChildNodeCount = chunk.html_child_node_count;
    this.element = document.createElement('div');
    this.element.dataset.chunkId = this.chunkId;
    this.element.dataset.chunkIndex = this.index.toString();
    this.element.dataset.chunkChildNodeCount = this.chunkChildNodeCount.toString();
    this.element.classList.add('rw-chunk-container');
    if (chunk.content) {
      this.hasContent = true;
      this._loadContentIntoElement(chunk.content);
    } else {
      this.hasContent = false;
      this._clearElementContent();
    }
  }

  setContent(content: string | null, sanitizationOptions: ChunkSanitizationOptions) {
    if (content && (!this.hasContent || !isEqual(sanitizationOptions, this._sanitizationOptions))) {
      this._sanitizationOptions = sanitizationOptions;
      this._loadContentIntoElement(content);
    } else if (!content && this.hasContent) {
      this._clearElementContent();
    }
  }

  destroy() {
    this._entryIntersectionObserver?.disconnect();
    this._exitIntersectionObserver?.disconnect();
    this._entryIntersectionObserver = null;
    this._exitIntersectionObserver = null;
  }

  startIntersectionObservers() {
    if (this.isSingleChunkFullContentContainer) {
      // no need to attach intersection observers to this chunk if it's the only chunk with the full content.
      return;
    }
    this._entryIntersectionObserver = new IntersectionObserver(
      (entries) => {
        const shouldHaveContent = entries.some((entry) => entry.isIntersecting);
        if (!shouldHaveContent || this.hasContent) {
          return;
        }
        logger.debug('IntersectionObserver: loading content', {
          id: this.chunkId,
          index: this.index,
        });
        triggerContentLoadForChunk(this.chunkId);
      },
      {
        threshold: 0,
        rootMargin: '1000px 0px',
      },
    );
    this._exitIntersectionObserver = new IntersectionObserver(
      (entries) => {
        const shouldHaveContent = entries.some((entry) => entry.isIntersecting);
        if (shouldHaveContent || !this.hasContent) {
          return;
        }
        logger.debug('IntersectionObserver: unloading content', {
          id: this.chunkId,
          index: this.index,
        });
        triggerContentUnloadForChunk(this.chunkId);
      },
      {
        threshold: 0,
        rootMargin: '4000px 0px',
      },
    );
    this._entryIntersectionObserver.observe(this.element);
    this._exitIntersectionObserver.observe(this.element);
  }

  get isSingleChunkFullContentContainer(): boolean {
    return this.chunkId === 'single-chunk-full-content';
  }

  private _clearElementContent() {
    let unloadedChunkHeight = window.innerHeight;
    if (this.hasContent) {
      logger.debug(`clearing element content`, { id: this.chunkId, index: this.index });
      unloadedChunkHeight = this.element.offsetHeight;
    }
    this.element.innerHTML = '';
    this.element.style.height = `${unloadedChunkHeight}px`;
    this.hasContent = false;
    eventEmitter.emit('chunk-content-unloaded', this.chunkId);
  }

  private _loadContentIntoElement(content: string) {
    logger.debug(`loading content into element`, { id: this.chunkId, index: this.index });
    const root = new DOMParser().parseFromString(content, 'text/html');
    const contentBody = root.body.innerHTML;
    // TODO: process <head> tags: scripts, styles, etc
    const sanitizedContent = rwSanitizeHtml(
      contentBody,
      this._sanitizationOptions.category,
      this._sanitizationOptions.isOriginalEmailView,
      this._sanitizationOptions.showEnhancedYouTubeTranscript,
    );
    this.element.innerHTML = sanitizedContent;
    if (this.isSingleChunkFullContentContainer) {
      // unchunked docs don't calculate child node count during parsing, so we need to calculate it here.
      // if we omit this, canonical location [de]serialization breaks. see convertCanonicalPositionToChunkAware().
      // however, to keep the code simpler and prevent inconsistencies, we don't (re)calculate child node count
      // for chunked documents here and instead continue to rely on values from the server.
      this.element.dataset.chunkChildNodeCount = this.element.childNodes.length.toString();
    }
    this.element.style.height = '';
    this.hasContent = true;
    eventEmitter.emit('chunk-content-loaded', this.chunkId);
  }
}

let chunkIdToContainerMap: { [chunkId: string]: ChunkContainer; } | null = null;
let initializationPromise: DeferredPromise<void> | null = null;
let initializedContentRoot: Element | null = null;

export function initChunkedContent(
  contentRoot: Element,
  chunks: WebviewDocumentChunk[],
  sanitizationOptions: ChunkSanitizationOptions,
) {
  // idempotent, so safe to run if chunked content has already been initialized.
  // however, this shouldn't be called twice, so we print a warning.
  if (initializationPromise) {
    logger.warn('chunked content already initialized, bailing');
    return;
  }
  initializationPromise = new DeferredPromise();
  initializedContentRoot = contentRoot;
  contentRoot.innerHTML = '';
  const containers = chunks.map((chunk) => new ChunkContainer(chunk, sanitizationOptions));
  chunkIdToContainerMap = Object.fromEntries(
    containers.map((container) => [container.chunkId, container]),
  );
  for (const container of containers) {
    contentRoot.appendChild(container.element);
  }
  const endOfContent = document.createElement('div');
  endOfContent.id = 'end-of-content';
  contentRoot.appendChild(endOfContent);
  contentRoot.classList.add('rw-chunk-containers-root');
  if (isDevOrTest) {
    window.chunkIdToContainerMap = chunkIdToContainerMap;
  }
  logger.debug('initialized chunked content', { chunks: chunks.map((c) => [c.id, Boolean(c.content)]) });
  initializationPromise.resolve();
  eventEmitter.emit('chunked-content-initialized');
}

export async function startChunkContainerIntersectionObservers() {
  // intersection observers detect when a chunk is about to enter or exit the viewing window.
  // if entering, triggers a load of that chunk's content.
  // if exiting, triggers an unload of that chunk's content.
  // you need to start them so that document content is correctly loaded.
  await waitForChunkedContentToBeInitialized();
  if (!chunkIdToContainerMap) {
    exceptionHandler.captureException('chunked content initialization failed, no chunkIdToContainerMap');
    return;
  }
  for (const container of Object.values(chunkIdToContainerMap)) {
    container.startIntersectionObservers();
  }
  logger.debug('started chunk container observers');
}

export async function updateChunkedContent(chunks: WebviewDocumentChunk[], sanitizationOptions: ChunkSanitizationOptions) {
  if (!initializationPromise || !chunkIdToContainerMap) {
    throw new Error('chunked content was not initialized: update');
  }
  await initializationPromise;
  for (const chunk of chunks) {
    const container: ChunkContainer | undefined = chunkIdToContainerMap[chunk.id];
    if (!container) {
      throw new Error(`no chunk container: ${chunk.id}`);
    }
    container.setContent(chunk.content, sanitizationOptions);
  }
  logger.debug('updated chunked content', { chunks: chunks.map((c) => [c.id, Boolean(c.content)]), sanitizationOptions });
}

export function destroyChunkedContent() {
  // idempotent, so it's safe to run even if chunked content hasn't been initialized.
  if (!chunkIdToContainerMap) {
    logger.warn('no chunked content to destroy, skipping');
    return;
  }
  for (const container of Object.values(chunkIdToContainerMap)) {
    container.destroy();
  }
  chunkIdToContainerMap = null;
  initializationPromise = null;
  initializedContentRoot = null;
  logger.debug('destroyed chunked content');
}

export function getChunkContainerStatus(chunkId: string): 'loaded' | 'unloaded' | 'nonexistent' {
  if (!chunkIdToContainerMap) {
    return 'nonexistent';
  }
  const container = chunkIdToContainerMap[chunkId];
  if (!container) {
    return 'nonexistent';
  }
  return container.hasContent ? 'loaded' : 'unloaded';
}

export function getChunkIndexToChunkIdMap(): { [chunkIndex: string]: string; } {
  if (!chunkIdToContainerMap) {
    throw new Error('getChunkIndexToChunkIdMap: chunked content was not initialized');
  }
  return Object.fromEntries(
    Object
      .values(chunkIdToContainerMap)
      .map((container) => [container.index.toString(), container.chunkId]),
  );
}

export function contentIsChunked() {
  if (chunkIdToContainerMap) {
    return true;
  }
  // chunked content may not be initialized yet, so we resort to detecting the CSS class in the content root.
  const contentRoot = document.getElementById('document-text-content');
  if (contentRoot) {
    return isChunkedDocumentContentRoot(contentRoot);
  }
  return false;
}

async function waitForChunkedContentToBeInitialized(): Promise<void> {
  if (initializationPromise) {
    return initializationPromise;
  }
  await eventEmitter.waitFor('chunked-content-initialized');
}

async function waitForChunkContentToLoad(chunkId: string): Promise<void> {
  if (!chunkIdToContainerMap) {
    logger.error('Content is not chunked, cannot wait for load', { chunkId });
    return;
  }
  const container = chunkIdToContainerMap[chunkId];
  if (!container) {
    logger.error('No container with chunk id, cannot wait for load', { chunkId });
    return;
  }
  if (container.hasContent) {
    return;
  }
  const promise = new DeferredPromise<void>();
  const onChunkContentLoaded = (loadedChunkId: string) => {
    if (loadedChunkId !== chunkId) {
      return;
    }
    eventEmitter.removeListener('chunk-content-loaded', onChunkContentLoaded);
    promise.resolve();
  };
  eventEmitter.addListener('chunk-content-loaded', onChunkContentLoaded);
  await promise;
}

export async function forceContentLoadForChunk(chunkId: string): Promise<void> {
  const promise = waitForChunkContentToLoad(chunkId);
  logger.debug('manually triggering load for chunk', { chunkId });
  triggerContentLoadForChunk(chunkId);

  return promise;
}

export async function emitEventWhenChunkContentAtPositionLoads(serializedPosition: string, eventToken: string): Promise<void> {
  await waitForChunkedContentToBeInitialized();
  if (!initializedContentRoot) {
    throw new Error('chunked content initialized but no content root');
  }
  const chunkAware = convertCanonicalPositionToChunkAware(serializedPosition, initializedContentRoot);
  if (!chunkAware) {
    exceptionHandler.captureException('could not convert serialized position to chunk aware', {
      extra: {
        serializedPosition,
      },
    });
    return;
  }
  await waitForChunkContentToLoad(chunkAware.chunkId);
  logger.debug('chunked content at position loaded', {
    serializedPosition,
    chunkId: chunkAware.chunkId,
  });
  await portalGateToForeground.emit('chunk-content-at-position-loaded', eventToken);
}

// NOTE: _Always_ fires an event, even when chunk content is already initialized at time of call.
export async function emitEventWhenChunkedContentInitialized(): Promise<void> {
  await waitForChunkedContentToBeInitialized();
  await portalGateToForeground.emit('chunk-content-initialized');
}
